import React from 'react';
import { Button } from 'react-bootstrap';
import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationsQuery,
  useUpdateOrganizationMutation,
} from '../../services/organizations';
import Profile from '../../components/Layouts/Profile';
import CreateModal from '../../components/Modals/Organizations/Create';
import EditModal from '../../components/Modals/Organizations/Edit';
import DeleteModal from '../../components/Modals/Organizations/Delete';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const ProfileOrganization = () => {
  const organizationsQuery = {
    role: 'employer',
    page: '0',
    size: 18,
  };

  const { data: organizations, isLoading } = useGetOrganizationsQuery(organizationsQuery);
  const [createOrganization, { isError: isErrorCreate }] = useCreateOrganizationMutation();
  const [updateOrganization, { isError: isErrorUpdate }] = useUpdateOrganizationMutation();
  const [deleteOrganization, { isError: isErrorDelete }] = useDeleteOrganizationMutation();

  const [message, setMessage] = React.useState(null);

  const [organization, setOrganization] = React.useState({});

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createOrganization({ role: 'employer', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Организация "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить организацию. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setOrganization(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateOrganization({
      role: 'employer',
      body: { id, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Организация "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить организацию.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setOrganization(obj);
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteOrganization({ role: 'employer', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Организация "${
            organizations.content.find((x) => x.id === response).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить организацию.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        organization={organization}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        id={organization?.id}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
      />

      <Profile>
        {organizations?.content?.length === 0 && (
          <Button variant="outline-primary" className="w-100 mb-4 py-2" onClick={modalShowCreate}>
            Добавить организацию
          </Button>
        )}
        {organizations?.content?.map((obj) => (
          <div className="organization-item" key={obj.id}>
            <div className="organization-item__info mb-3">
              <p className="organization-name mb-0">
                <span className="text-uppercase text-secondary">Наименование: </span>
                {obj.name}
              </p>
              <p className="organization-phone mb-0">
                <span className="text-uppercase text-secondary">Телефон: </span> {obj.phone}
              </p>
              <p className="organization-address mb-0">
                <span className="text-uppercase text-secondary">Адрес: </span>
                {obj.city && obj.city.startsWith('г.') ? `${obj?.city}, ` : `г. ${obj?.city}, `}
                {obj.address}
                {obj?.region ? `, ${obj.region}` : ''}
              </p>
            </div>

            <div className="organization-item__btns d-flex gap-2">
              <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                <FaEdit /> Редактировать
              </Button>

              <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                <FaTrashAlt /> Удалить
              </Button>
            </div>
          </div>
        ))}
      </Profile>
    </>
  );
};

export default ProfileOrganization;
