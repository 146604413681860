import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetManagersQuery,
  useCreateManagerMutation,
  useUpdateManagerMutation,
  useDeleteManagerMutation,
} from '../../../../services/managers';

import { useGetOrganizationsQuery } from '../../../../services/organizations';

import CreateModal from '../../../../components/Modals/Managers/Create';
import EditModal from '../../../../components/Modals/Managers/Edit';
import DeleteModal from '../../../../components/Modals/Managers/Delete';

import AlertMessage from '../../../../components/AlertMessage';
import PaginationCustom from '../../../../components/PaginationCustom';

import './Managers.scss';

const Managers = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: managers, isLoading } = useGetManagersQuery({ size: 20, page });
  const [createManager, { isError: isErrorCreate }] = useCreateManagerMutation();
  const [updateManager, { isError: isErrorUpdate }] = useUpdateManagerMutation();
  const [deleteManager, { isError: isErrorDelete }] = useDeleteManagerMutation();

  const organizationsQuery = {
    role: 'admin',
    page: 0,
    size: 300,
  };

  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [manager, setManager] = React.useState([]);

  const [deleteMessage, setDeleteMessage] = React.useState(null);

  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.organization = { id: values.organization };
    createManager(values)
      .unwrap()
      .then((response) => {
        setMessage(`Менеджер успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить менеджера. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setManager(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.organization = { id: values.organization };
    updateManager({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Менеджер успешно отредактирован.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось отредактировать менеджера.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setManager(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить менеджера организации ${
        managers.find((x) => x.id === obj.id).organization?.name ?? ''
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteManager(id)
      .unwrap()
      .then((response) => {
        setMessage(`Менеджер успешно удален.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить менеджера.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Менеджеры организации';
  }, []);

  return (
    <>
      <CreateModal
        organizations={organizations?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        manager={manager}
        organizations={organizations?.content ?? []}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        manager={manager}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-managers my-5">
        <div className="container">
          <div className="section-title">
            <h1>Менеджеры организаций</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
            Добавить менеджера
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <div className="managers mb-3">
                {managers.content.map((obj) => (
                  <div
                    key={obj.id}
                    className="manager-item bg-body box-shadow p-3 rounded-1 border border-light">
                    <h5 className="manager-item__organization">{obj.name}</h5>
                    <div className="manager-item__email">{obj.email}</div>
                    <div className="manager-item__name">
                      {obj.lastName} {obj.firstName} {obj.secondName}
                    </div>

                    <div className="manager-item__btns">
                      <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                        <FaEdit /> Редактировать
                      </Button>

                      <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                        <FaTrashAlt /> Удалить
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              <PaginationCustom totalPages={managers.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Managers;
