import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FaBurn, FaBook, FaPlus, FaMinus } from 'react-icons/fa';

import { addProgram, removeProgram } from '../../../redux/slices/selectProgramsSlice';
import { useParams } from 'react-router-dom';

const isSelectedProgram = (programms, name) => {
  return programms.some((x) => x.name === name);
};

const EducationModuleItem = ({ id, name, themes, competentionName }) => {
  const { slug_skill } = useParams();
  const dispatch = useDispatch();
  const selectedPrograms = useSelector((state) => state.selectPrograms.items);

  let profoblast = slug_skill.replace('-', ' ');
  profoblast = profoblast.charAt(0).toUpperCase() + profoblast.slice(1);

  const onSelectProgramm = () => {
    dispatch(addProgram({ id, name, themes, competentionName, profoblast }));
  };

  const onRemoveProgramm = () => {
    dispatch(removeProgram(name));
  };

  return (
    <div className="module-item box-shadow bg-body">
      <div className="module-content">
        <div className="module-title">{name}</div>
        <div className="module-list">
          {themes?.map((theme) => (
            <div key={theme?.id} className="module-list__item">
              <FaBook /> {theme.name}
            </div>
          ))}
        </div>

        <div className="module-type">
          <FaBurn /> Модуль
        </div>
      </div>
      {isSelectedProgram(selectedPrograms, name) ? (
        <button className="module-add" onClick={onRemoveProgramm}>
          <FaMinus /> Убрать
        </button>
      ) : (
        <button className="module-add" onClick={onSelectProgramm}>
          <FaPlus /> Выбрать
        </button>
      )}
    </div>
  );
};

export default EducationModuleItem;
