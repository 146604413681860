import React from 'react';

import { Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetProfessionalObjectsQuery,
  useCreateProfessionalObjectMutation,
  useUpdateProfessionalObjectMutation,
  useDeleteProfessionalObjectMutation,
} from '../../../../services/professionalObjects';

import { useGetCompetentionsQuery } from '../../../../services/competentions';

import CreateModal from '../../../../components/Modals/ProfessionalObjects/Create';
import DeleteModal from '../../../../components/Modals/ProfessionalObjects/Delete';
import EditModal from '../../../../components/Modals/ProfessionalObjects/Edit';

import AlertMessage from '../../../../components/AlertMessage';

import './ProfessionalObjects.scss';

const ProfessionalObjects = () => {
  const { data: professionalObjects, isLoading } = useGetProfessionalObjectsQuery();
  const [createProfessionalObject, { isError: isErrorCreate }] =
    useCreateProfessionalObjectMutation();
  const [updateProfessionalObject, { isError: isErrorUpdate }] =
    useUpdateProfessionalObjectMutation();
  const [deleteProfessionalObject, { isError: isErrorDelete }] =
    useDeleteProfessionalObjectMutation();

  const { data: competentions } = useGetCompetentionsQuery(200);

  const [image, setImage] = React.useState({ name: '', file: '' });

  const [professionalObject, setProfessionalObject] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.competentions = values.competentions.map((x) => ({ id: x }));
    createProfessionalObject({ ...values, image })
      .unwrap()
      .then((response) => {
        setMessage(`Профессиональная область "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить профессиональную область.  ${err?.violations?.map(
            (x) => x.message + '; ',
          )}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setProfessionalObject(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.competentions = values.competentions.map((x) => ({ id: x }));
    updateProfessionalObject(image.name ? { id, image, ...values } : { id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Профессиональная область "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить профессиональную область.  ${err?.violations?.map(
            (x) => x.message + '; ',
          )}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setProfessionalObject(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить профессиональную область ${
        professionalObjects.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteProfessionalObject(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `Профессиональная область "${
            professionalObjects.content.find((x) => x.id === response).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить профессиональную область.  ${err?.violations?.map(
            (x) => x.message + '; ',
          )}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Профессиональные области';
  }, []);

  return (
    <>
      <CreateModal
        setImage={setImage}
        competentions={competentions?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        setImage={setImage}
        competentions={competentions?.content ?? []}
        professionalObject={professionalObject}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        id={professionalObject?.id}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-professional-objects my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Профессиональные области</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
              Добавить профессиональную область
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка ...</h1>
          ) : (
            <div className="professional-objects">
              {professionalObjects.content.map((obj) => (
                <div
                  key={obj.id}
                  className="professional-objects__item bg-body box-shadow rounded-1 border border-light">
                  <img
                    className="professional-objects__item-img"
                    src={obj.image ? process.env.REACT_APP_API_URL + obj.image.src : ''}
                    alt={obj.name}
                  />

                  <h5 className="professional-objects__item-name">{obj.name}</h5>

                  <div className="professional-objects__item-btns">
                    <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                      <FaEdit /> Редактировать
                    </Button>

                    <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                      <FaTrashAlt /> Удалить
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ProfessionalObjects;
