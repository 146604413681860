import React from 'react';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetProgramTypesQuery,
  useCreateProgramTypeMutation,
  useUpdateProgramTypeMutation,
  useDeleteProgramTypeMutation,
} from '../../../../services/programTypes';

import CreateModal from '../../../../components/Modals/ProgrammTypes/Create';
import DeleteModal from '../../../../components/Modals/ProgrammTypes/Delete';
import EditModal from '../../../../components/Modals/ProgrammTypes/Edit';

import './ProgramTypes.scss';
import AlertMessage from '../../../../components/AlertMessage';

const ProgramTypes = () => {
  const { data: programTypes, isLoading } = useGetProgramTypesQuery();
  const [createProgramType, { isError: isErrorCreate }] = useCreateProgramTypeMutation();
  const [updateProgramType, { isError: isErrorUpdate }] = useUpdateProgramTypeMutation();
  const [deleteProgramType, { isError: isErrorDelete }] = useDeleteProgramTypeMutation();

  const [type, setType] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createProgramType(values)
      .unwrap()
      .then((response) => {
        setMessage(`Вид программы "${response.name}" успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить вид программы.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (type) => {
    setType(type);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateProgramType({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Вид программы "${response.name}" успешно изменен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить вид программы.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (type) => {
    setType(type);
    setDeleteMessage(
      `Вы уверены, что хотите удалить вид программы ${
        programTypes.content.find((x) => x.id === type.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteProgramType(id)
      .unwrap()
      .then(() => {
        setMessage(
          `Вид программы "${programTypes.content.find((x) => x.id === id).name}" успешно удалён.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить вид программы "${
            programTypes.content.find((x) => x.id === id).name
          }".  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Виды программ';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        type={type}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        id={type?.id}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-program-types my-5">
        <div className="container">
          <div className="section-title">
            <h1>Виды программ</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
              Добавить вид программы
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <div className="bg-body box-shadow p-3 rounded-1 border border-light">
              <Table bordered responsive className="text-center mb-0">
                <thead className="align-middle">
                  <tr>
                    <th>Наименование</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {programTypes?.content.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>

                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt size="1.1em" color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ProgramTypes;
