import React from 'react';

import { FaFireAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Education({ slug, image, name }) {
  return (
    <Link className="education-item box-shadow bg-body" to={`/educations/${slug}`}>
      <div className="education-img">
        <img src={image ? process.env.REACT_APP_API_URL + image.src : ''} alt={name} />
      </div>

      <div className="education-content">
        <div className="education-title">{name}</div>
        <div className="education-professional-objects">
          {<FaFireAlt />} Профессиональная область
        </div>
      </div>
    </Link>
  );
}

export default Education;
