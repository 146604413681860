import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Table } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetCompetentionsQuery,
  useCreateCompetentionMutation,
  useUpdateCompetentionMutation,
  useDeleteCompetentionMutation,
} from '../../../../services/competentions';

import CreateModal from '../../../../components/Modals/Competentions/Create';
import DeleteModal from '../../../../components/Modals/Competentions/Delete';
import EditModal from '../../../../components/Modals/Competentions/Edit';

import AlertMessage from '../../../../components/AlertMessage';
import PaginationCustom from '../../../../components/PaginationCustom';

import './Competentions.scss';

const Competentions = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: competentions, isLoading } = useGetCompetentionsQuery({ size: 20, page });
  const [createCompetention, { isError: isErrorCreate }] = useCreateCompetentionMutation();
  const [updateCompetention, { isError: isErrorUpdate }] = useUpdateCompetentionMutation();
  const [deleteCompetention, { isError: isErrorDelete }] = useDeleteCompetentionMutation();

  const [competention, setCompetention] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalShowCreate = () => setShowCreate(true);
  const modalCloseCreate = () => setShowCreate(false);
  const submitCreate = (values) => {
    createCompetention(values)
      .unwrap()
      .then((response) => {
        setMessage(`Компетенция "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить компетенцию.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalShowEdit = (obj) => {
    setCompetention(obj);
    setShowEdit(true);
  };
  const modalCloseEdit = () => setShowEdit(false);
  const submitEdit = (id, values) => {
    updateCompetention({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Компетенция "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить компетенцию.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalShowDelete = (obj) => {
    setCompetention(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить компетенцию ${
        competentions.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const modalCloseDelete = () => setShowDelete(false);
  const submitDelete = (id) => {
    deleteCompetention(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `Компетенция "${
            competentions.content.find((x) => x.id === response).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить компетенцию.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Компетенции';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        competention={competention}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        id={competention?.id}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-competentions my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Компетенции</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
              Добавить компетенцию
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <div className="bg-body box-shadow p-3 rounded-1 border border-light">
              <Table bordered responsive className="text-center mb-0">
                <thead className="align-middle">
                  <tr>
                    <th>Наименование</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {competentions?.content?.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>

                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt size="1.1em" color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom
                totalPages={competentions?.totalPages}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Competentions;
