import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetNewsByIdQuery } from '../../../services/news';

import NotFound from '../../../pages/NotFound';

import LoaderNewsDetail from '../../../components/Loaders/NewsDetail';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import '@fancyapps/ui/dist/fancybox.esm.js';
import '@fancyapps/ui/dist/fancybox.css';

import './NewsDetail.scss';
import { PhotoViewIcon } from '../../../components/PhotoViewIcon';

const NewsDetail = () => {
  const { id } = useParams();

  const { data: news, isLoading } = useGetNewsByIdQuery(id);

  React.useEffect(() => {
    document.title = 'ЦОПП - Просмотр новости';
    window.scroll(0, 0);
  }, []);

  if (news === null) {
    return <NotFound />;
  }

  return (
    <div className="container">
      <div className="news-detail__wrapper my-5">
        {isLoading ? (
          <LoaderNewsDetail />
        ) : (
          <>
            <div className="news-detail">
              <h4 className="news-title">{news?.name}</h4>
              <hr />
              <div className="news-date">{new Date(news?.created).toLocaleDateString()}</div>
              <div className="news-content">{news?.description}</div>
            </div>

            {news?.image && (
              <div className="news-img detail">
                <a href={process.env.REACT_APP_API_URL + news?.image.src} data-fancybox>
                  <img src={process.env.REACT_APP_API_URL + news?.image.src} alt={news?.name} />

                  <PhotoViewIcon />
                </a>
              </div>
            )}

            {news?.images.length > 0 && (
              <div className="mt-4">
                <Swiper
                  modules={[Navigation]}
                  navigation={true}
                  slidesPerView={3}
                  spaceBetween={20}
                  autoHeight={true}>
                  {news?.images.map((x) => (
                    <SwiperSlide key={x.id}>
                      <a href={process.env.REACT_APP_API_URL + x.src} data-fancybox>
                        <img src={process.env.REACT_APP_API_URL + x.src} alt={x.name} />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NewsDetail;
