import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FaEdit, FaEye, FaPlus, FaQuestion, FaRegNewspaper, FaTrashAlt } from 'react-icons/fa';

import {
  useGetNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
} from '../../services/news';

import {
  useGetStatsQuery,
  useCreateStatsMutation,
  useUpdateStatsMutation,
  useDeleteStatsMutation,
} from '../../services/stats';

import {
  useCreatePartnersMutation,
  useDeletePartnersMutation,
  useGetPartnersQuery,
  useUpdatePartnersMutation,
} from '../../services/partners';

import { useCreateFeedbackMutation } from '../../services/feedback';

import News from '../../components/News';
import LoaderNews from '../../components/Loaders/News';

import CreateModal from '../../components/Modals/News/Create';
import EditModal from '../../components/Modals/News/Edit';
import DeleteModal from '../../components/Modals/News/Delete';

import CreateModalStats from '../../components/Modals/Stats/Create';
import EditModalStats from '../../components/Modals/Stats/Edit';
import DeleteModalStats from '../../components/Modals/Stats/Delete';

import CreateModalPartners from '../../components/Modals/Partners/Create';
import EditModalPartners from '../../components/Modals/Partners/Edit';
import DeleteModalPartners from '../../components/Modals/Partners/Delete';

import AlertMessage from '../../components/AlertMessage';
import PaginationCustom from '../../components/PaginationCustom';
import Feedback from '../../components/Modals/Feedback';

import useAuth from '../../hooks/useAuth';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Home.scss';
import ReCAPTCHA from 'react-google-recaptcha';

const Home = () => {
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: news, isLoading } = useGetNewsQuery({ size: 5, page });
  const [createNews, { isError: isErrorCreate }] = useCreateNewsMutation();
  const [updateNews, { isError: isErrorUpdate }] = useUpdateNewsMutation();
  const [deleteNews, { isError: isErrorDelete }] = useDeleteNewsMutation();

  const { data: stats, isLoadingStats } = useGetStatsQuery();
  const [createStats, { isError: isErrorCreateStats }] = useCreateStatsMutation();
  const [updateStats, { isError: isErrorUpdateStats }] = useUpdateStatsMutation();
  const [deleteStats, { isError: isErrorDeleteStats }] = useDeleteStatsMutation();

  const { data: partners, isLoadingPartners } = useGetPartnersQuery();
  const [createPartners, { isError: isErrorCreatePartners }] = useCreatePartnersMutation();
  const [updatePartners, { isError: isErrorUpdatePartners }] = useUpdatePartnersMutation();
  const [deletePartners, { isError: isErrorDeletePartners }] = useDeletePartnersMutation();

  const [createFeedback, { isError: isErrorCreateFeedback }] = useCreateFeedbackMutation();

  const { isAdmin } = useAuth();

  const [image, setImage] = React.useState({ name: '', file: '' });
  const [logo, setLogoPartners] = React.useState({ name: '', file: '' });
  const [images, setImages] = React.useState([]);

  const [data, setData] = React.useState({});

  const [message, setMessage] = React.useState(null);
  const [deleteMessage, setDeleteMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createNews({ ...values, image, images, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`Новость "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить новость.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setData(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateNews(
      image.file && images.length === 0
        ? { id, ...values, image, status: 'ACTIVE' }
        : images.length > 0 && !image.file
        ? { id, ...values, images, status: 'ACTIVE' }
        : image.file && images.length > 0
        ? { id, ...values, image, images, status: 'ACTIVE' }
        : { id, ...values, status: 'ACTIVE' },
    )
      .unwrap()
      .then((response) => {
        setMessage(`Новость "${response.name}" успешно обновлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить новость. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setData(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить новость ${
        news?.content?.find((x) => x.id === obj.id)?.name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteNews(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `Новость "${news?.content?.find((x) => x.id === response)?.name}" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить новость. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  // Обратная взязь
  const [showFeedback, setShowFeedback] = React.useState(false);

  const modalCloseFeedback = () => setShowFeedback(false);
  const modalShowFeedback = () => setShowFeedback(true);
  const submitFeedback = (values) => {
    createFeedback(values)
      .unwrap()
      .then((response) => {
        setMessage(`Запрос успешно отправлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось отправить запрос. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseFeedback();
  };

  // Добавление, обновление и удаление статистики
  const [dataStats, setDataStats] = React.useState({});

  const [showCreateStats, setShowCreateStats] = React.useState(false);
  const [showEditStats, setShowEditStats] = React.useState(false);
  const [showDeleteStats, setShowDeleteStats] = React.useState(false);

  const modalCloseCreateStats = () => setShowCreateStats(false);
  const modalShowCreateStats = () => setShowCreateStats(true);
  const submitCreateStats = (values) => {
    createStats(values)
      .unwrap()
      .then((response) => {
        setMessage(`Показатель успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить показатель.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreateStats();
  };
  const modalCloseEditStats = () => setShowEditStats(false);
  const modalShowEditStats = (obj) => {
    setDataStats(obj);
    setShowEditStats(true);
  };
  const submitEditStats = (id, values) => {
    updateStats({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Показатель успешно обновлен.`);
      })
      .catch((err) => {
        window.scroll(0, 0);
        setMessage(
          `Не удалось обновить показатель. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEditStats();
  };

  const modalCloseDeleteStats = () => setShowDeleteStats(false);
  const modalShowDeleteStats = (obj) => {
    setDataStats(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить показатель ${
        stats?.content?.find((x) => x.id === obj.id)?.text
      }?`,
    );
    setShowDeleteStats(true);
  };
  const submitDeleteStats = (id) => {
    deleteStats(id)
      .unwrap()
      .then((response) => {
        setMessage(`Показатель успешно удален.`);
      })
      .catch((err) => {
        window.scroll(0, 0);
        setMessage(
          `Не удалось удалить показатель. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDeleteStats();
  };

  // Добавление, обновление и удаление партнёров
  const [dataPartners, setDataPartners] = React.useState({});

  const [showCreatePartners, setShowCreatePartners] = React.useState(false);
  const [showEditPartners, setShowEditPartners] = React.useState(false);
  const [showDeletePartners, setShowDeletePartners] = React.useState(false);

  const modalCloseCreatePartners = () => setShowCreatePartners(false);
  const modalShowCreatePartners = () => setShowCreatePartners(true);
  const submitCreatePartners = (values) => {
    createPartners({ ...values, logo, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`Партнёр успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить партнёра.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreatePartners();
  };
  const modalCloseEditPartners = () => setShowEditPartners(false);
  const modalShowEditPartners = (obj) => {
    setDataPartners(obj);
    setShowEditPartners(true);
  };
  const submitEditPartners = (id, values) => {
    updatePartners(
      logo.file ? { id, ...values, logo, status: 'ACTIVE' } : { id, ...values, status: 'ACTIVE' },
    )
      .unwrap()
      .then((response) => {
        setMessage(`Партнёр успешно обновлен.`);
      })
      .catch((err) => {
        window.scroll(0, 0);
        setMessage(
          `Не удалось обновить партнёра. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEditPartners();
  };

  const modalCloseDeletePartners = () => setShowDeletePartners(false);
  const modalShowDeletePartners = (obj) => {
    setDataPartners(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить партнёра ${
        partners?.content?.find((x) => x.id === obj.id)?.name
      }?`,
    );
    setShowDeletePartners(true);
  };
  const submitDeletePartners = (id) => {
    deletePartners(id)
      .unwrap()
      .then((response) => {
        setMessage(`Партнёр успешно удален.`);
      })
      .catch((err) => {
        window.scroll(0, 0);
        setMessage(
          `Не удалось удалить партнёра. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDeletePartners();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Главная';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
        setImage={setImage}
        setImages={setImages}
      />

      <EditModal
        data={data}
        setImage={setImage}
        setImages={setImages}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        data={data}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <CreateModalStats
        submitCreate={submitCreateStats}
        showCreate={showCreateStats}
        modalCloseCreate={modalCloseCreateStats}
      />

      <EditModalStats
        data={dataStats}
        showEdit={showEditStats}
        submitEdit={submitEditStats}
        modalCloseEdit={modalCloseEditStats}
      />

      <DeleteModalStats
        data={dataStats}
        showDelete={showDeleteStats}
        modalCloseDelete={modalCloseDeleteStats}
        submitDelete={submitDeleteStats}
        deleteMessage={deleteMessage}
      />

      <CreateModalPartners
        submitCreate={submitCreatePartners}
        showCreate={showCreatePartners}
        modalCloseCreate={modalCloseCreatePartners}
        setLogoPartners={setLogoPartners}
      />

      <EditModalPartners
        data={dataPartners}
        setImage={setLogoPartners}
        showEdit={showEditPartners}
        submitEdit={submitEditPartners}
        modalCloseEdit={modalCloseEditPartners}
      />

      <DeleteModalPartners
        data={dataPartners}
        showDelete={showDeletePartners}
        modalCloseDelete={modalCloseDeletePartners}
        submitDelete={submitDeletePartners}
        deleteMessage={deleteMessage}
      />

      <section className="section section-news my-5">
        <div className="container">
          <div className="section-title">
            <h1 className="text-center">Новости</h1>
            <hr />
          </div>

          {isAdmin && (
            <Button
              variant="default"
              className="btn btn-outline-secondary w-100 mb-4"
              onClick={modalShowCreate}>
              <FaRegNewspaper className="me-2" />
              Добавить новость
            </Button>
          )}

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={
                isErrorCreate ||
                isErrorUpdate ||
                isErrorDelete ||
                isErrorCreatePartners ||
                isErrorUpdatePartners ||
                isErrorDeletePartners
              }
            />
          )}

          {isLoading ? (
            [...new Array(5)].map((_, index) => <LoaderNews key={index} />)
          ) : (
            <>
              <div className="news">
                {news?.content?.map((obj) => (
                  <News
                    obj={obj}
                    key={obj.id}
                    isAdmin={isAdmin}
                    modalShowEdit={modalShowEdit}
                    modalShowDelete={modalShowDelete}
                  />
                ))}
              </div>

              <PaginationCustom totalPages={news?.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>

      <section className="section-statistics my-5">
        <div className="container">
          {isAdmin && (
            <Button
              variant="default"
              className="btn btn-outline-secondary w-100 mb-4"
              onClick={modalShowCreateStats}>
              <FaPlus className="me-2" />
              Добавить показатель
            </Button>
          )}

          <div className="statistics">
            {stats?.content?.map((x) => (
              <div className="statistics-item box-shadow" key={x?.id}>
                <div className="statistics-item__number">
                  {String(x?.number).replace(/(.)(?=(\d{3})+$)/g, '$1 ')}
                </div>
                <div className="statistics-item__text">{x?.text}</div>

                {isAdmin && (
                  <div className="statistics-item__btns">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={() => modalShowEditStats(x)}>
                      <FaEdit size="3em" />
                    </button>

                    <button
                      className="btn btn-outline-danger w-100"
                      onClick={() => modalShowDeleteStats(x)}>
                      <FaTrashAlt size="3em" />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {partners?.content?.length > 0 && !isAdmin && (
        <section className="section-partners my-5">
          <div className="container">
            <div className="section-title">
              <h1 className="text-center">Работодатели и партнеры</h1>
              <hr />
            </div>

            {isAdmin && (
              <Button
                variant="default"
                className="btn btn-outline-secondary w-100 mb-4"
                onClick={modalShowCreatePartners}>
                <FaPlus className="me-2" />
                Добавить работодателя/партнёра
              </Button>
            )}

            {isLoadingPartners ? (
              [...new Array(5)].map((_, index) => <LoaderNews key={index} />)
            ) : (
              <Swiper
                className="partners"
                navigation={true}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                  },
                  1280: {
                    slidesPerView: 3,
                  },
                }}
                spaceBetween={20}>
                {partners?.content?.map((x) => (
                  <SwiperSlide className="partners-item" key={x?.id}>
                    <a href={x?.link} target={'_blank'} rel="noreferrer">
                      <img src={process.env.REACT_APP_API_URL + x?.logo?.src} alt={x?.name} />
                    </a>

                    {isAdmin && (
                      <div className="partners-item__btns">
                        <a
                          href={x?.link}
                          target={'_blank'}
                          rel="noreferrer"
                          className="btn btn-outline-success w-100">
                          <FaEye size="3em" />
                        </a>

                        <button
                          className="btn btn-outline-primary w-100"
                          onClick={() => modalShowEditPartners(x)}>
                          <FaEdit size="3em" />
                        </button>

                        <button
                          className="btn btn-outline-danger w-100"
                          onClick={() => modalShowDeletePartners(x)}>
                          <FaTrashAlt size="3em" />
                        </button>
                      </div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </section>
      )}

      <Feedback
        submitFeedback={submitFeedback}
        showFeedback={showFeedback}
        modalCloseFeedback={modalCloseFeedback}
      />

      {/* <ReCAPTCHA sitekey="6LcxNyojAAAAAETNwFF26BLa7tTWn4FJDgrLbnMD" onChange={onChange} /> */}

      <button className="feedback-button" onClick={modalShowFeedback}>
        <FaQuestion />
      </button>
    </>
  );
};

export default Home;
