import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaArrowRight,
  FaChartLine,
  FaFilter,
  FaWindowClose,
} from 'react-icons/fa';

import {
  useGetEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
} from '../../services/events';

import { useGetEventTypesQuery } from '../../services/eventTypes';
import { useGetTargetGroupsQuery } from '../../services/targetGroups';

import FilterEvents from '../../components/Modals/FilterEvents';
import LoaderEvents from '../../components/Loaders/Events';
import EventItem from '../../components/Events';

import CreateModal from '../../components/Modals/Events/Create';
import EditModal from '../../components/Modals/Events/Edit';
import DeleteModal from '../../components/Modals/Events/Delete';

import AlertMessage from '../../components/AlertMessage';
import PaginationCustom from '../../components/PaginationCustom';

import useAuth from '../../hooks/useAuth';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import './Events.scss';

const Events = () => {
  const { data, isAuth, isAdmin, isManager, isUser } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );
  const [date, setDate] = React.useState(
    searchParams.has('date') ? searchParams.get('date') : null,
  );

  const nameOfFilter = searchParams.get('name') ?? null;
  const typeOfFilter = searchParams.get('type') ?? null;
  const targetGroupOfFilter = searchParams.get('targetGroup') ?? null;

  const isFilters = () => date || nameOfFilter || typeOfFilter || targetGroupOfFilter;

  const clearFilters = () => {
    setSearchParams({});
    setDate(null);
  };

  const query = {
    size: 20,
    page,
    role: isAdmin ? 'admin' : isManager ? 'manager' : 'user',
    date,
    name: nameOfFilter,
    type: typeOfFilter,
    targetGroup: targetGroupOfFilter,
  };

  const { data: events, isLoading } = useGetEventsQuery(query);

  const [createEvent, { isError: isErrorCreate }] = useCreateEventMutation();
  const [updateEvent, { isError: isErrorUpdate }] = useUpdateEventMutation();
  const [deleteEvent, { isError: isErrorDelete }] = useDeleteEventMutation();

  const { data: types } = useGetEventTypesQuery(40);
  const { data: targets } = useGetTargetGroupsQuery();

  const [image, setImage] = React.useState({ name: '', file: '' });

  const [filterIsOpen, setFilterIsOpen] = React.useState(false);
  // const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [event, setEvent] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.type = { id: values.type };
    values.targetGroup = { id: values.targetGroup };
    values.author = { id: data.id ? data.id : data.user.id };

    createEvent({
      role: isAdmin ? 'admin' : 'manager',
      body: image.file ? { ...values, image } : values,
    })
      .unwrap()
      .then((response) => {
        setMessage(`Мероприятие "${response.name}" успешно добавлено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить мероприятие. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setEvent(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.type = { id: values.type };
    values.targetGroup = { id: values.targetGroup };
    values.author = { id: data.id ? data.id : data.user.id };

    updateEvent({
      role: isAdmin ? 'admin' : 'manager',
      body: image.file ? { id, ...values, image } : { id, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Мероприятие "${response.name}" успешно изменено.`);
        setImage({ name: '', file: '' });
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить мероприятие.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setEvent(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить мероприятие ${
        events.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteEvent({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Мероприятие "${events.content.find((x) => x.id === response).name}" успешно удалено.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить мероприятие.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Мероприятия';
  }, []);

  return (
    <>
      <FilterEvents
        filterIsOpen={filterIsOpen}
        setFilterIsOpen={setFilterIsOpen}
        types={types?.content ?? []}
        targets={targets?.content ?? []}
      />

      {(isAdmin || isManager) && (
        <>
          <CreateModal
            types={types?.content ?? []}
            targets={targets?.content ?? []}
            setImage={setImage}
            submitCreate={submitCreate}
            showCreate={showCreate}
            modalCloseCreate={modalCloseCreate}
          />

          <EditModal
            event={event}
            types={types?.content ?? []}
            targets={targets?.content ?? []}
            setImage={setImage}
            showEdit={showEdit}
            submitEdit={submitEdit}
            modalCloseEdit={modalCloseEdit}
          />

          <DeleteModal
            event={event}
            showDelete={showDelete}
            modalCloseDelete={modalCloseDelete}
            submitDelete={submitDelete}
            deleteMessage={deleteMessage}
          />
        </>
      )}

      <section className="section section-events my-5">
        <div className="container">
          <div className="section-title">
            <h1>Мероприятия</h1>
            <hr />

            <div className="filters-wrapper" onClick={() => setFilterIsOpen(true)}>
              <div className="filters">
                <FaFilter /> Фильтр
              </div>
              <span>{isLoading ? '...' : events?.content?.length}</span>
            </div>

            {isFilters() && (
              <button className="btn btn-primary mb-3 fw-bold" onClick={clearFilters}>
                <FaWindowClose size={36} className="me-2" /> Очистить фильтры
              </button>
            )}
          </div>

          {(isAdmin || isManager) && (
            <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
              Добавить мероприятие
            </Button>
          )}

          {isAdmin && (
            <Button
              variant="outline-primary"
              className="w-100 mb-3 py-2"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_API_URL}/statistics/users-events/`)
              }>
              Выгрузить статистику
            </Button>
          )}

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          <Calendar
            className="mb-4 w-100"
            onChange={(value, event) => {
              const dateFormatted = value.toLocaleDateString().split('.').reverse().join('-');
              setSearchParams({ date: dateFormatted });
              setDate(dateFormatted);
            }}
            value={date ? new Date(date) : new Date()}
            // maxDate={new Date()}
            prevLabel={<FaAngleLeft />}
            prev2Label={<FaAngleDoubleLeft />}
            nextLabel={<FaAngleRight />}
            next2Label={<FaAngleDoubleRight />}
          />

          <div className="events-wrapper">
            {/* {!isAdmin && (
              <button className="events-request" onClick={() => setModalIsOpen(true)}>
                Хочу провести мероприятие
              </button>
            )} */}

            {isLoading ? (
              [...new Array(6)].map((_, index) => <LoaderEvents key={index} />)
            ) : (
              <>
                <div className="events mb-4">
                  {events?.content?.map((obj) => (
                    <EventItem
                      key={obj.id}
                      obj={obj}
                      modalShowEdit={modalShowEdit}
                      modalShowDelete={modalShowDelete}
                      userId={data?.id}
                      isAuth={isAuth}
                      isAdmin={isAdmin}
                      isManager={isManager}
                      isUser={isUser}
                    />
                  ))}
                </div>

                <PaginationCustom totalPages={events.totalPages} page={page} setPage={setPage} />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
