import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { Nav } from 'react-bootstrap';

import { logout } from '../../redux/slices/authSlice';

import {
  FaAddressBook,
  FaBuilding,
  FaFile,
  FaHistory,
  FaInfoCircle,
  FaListUl,
  FaSignOutAlt,
  FaUserCircle,
} from 'react-icons/fa';

import useAuth from '../../hooks/useAuth';

import dogovor from '../../assets/docs/Dogovor_po_mobilizacii_ShABLON.docx';
import dogovorCP from '../../assets/docs/DOGOVOR-O-CELEVOM.docx';

const Profile = ({ children }) => {
  const dispatch = useDispatch();
  const { isUser, isAuth, isEmployer, isManager } = useAuth();

  React.useEffect(() => {
    document.title = 'ЦОПП - Профиль';
  }, []);

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  const onLogout = () => {
    dispatch(logout());
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
  };

  return (
    <>
      <section className="section-profile flex-grow-1">
        <div className="container-fluid p-3">
          {(isEmployer || isManager) && (
            <>
              <a href={dogovor} download className="d-block text-center mb-4">
                Договор о трудоустройстве обучающихся на вакансию в период частичной мобилизации по
                индивидуальному учебному плану
              </a>

              <a href={dogovorCP} download className="d-block text-center mb-4">
                Договор о целевом обучении по образовательной программе
              </a>
            </>
          )}

          <div className="d-flex gap-3 flex-wrap">
            <div
              className="col-12 col-xl-3 bg-body box-shadow rounded-1 p-3"
              style={{ height: 'fit-content' }}>
              <Nav variant="pills" className="flex-column" activeKey={window.location.pathname}>
                <Nav.Item>
                  <Nav.Link eventKey="/profile" to="/profile" as={Link}>
                    <FaUserCircle className="me-2" size="1.5em" /> Личные данные
                  </Nav.Link>
                </Nav.Item>

                {isUser && (
                  <Nav.Item>
                    <Nav.Link eventKey="/profile/resumes" to="/profile/resumes" as={Link}>
                      <FaFile className="me-2" size="1.5em" /> Резюме
                    </Nav.Link>
                  </Nav.Item>
                )}

                {isEmployer && (
                  <Nav.Item>
                    <Nav.Link eventKey="/profile/organization" to="/profile/organization" as={Link}>
                      <FaBuilding className="me-2" size="1.5em" /> Организация
                    </Nav.Link>
                  </Nav.Item>
                )}

                {!isManager && (
                  <Nav.Item>
                    <Nav.Link eventKey="/profile/requests" to="/profile/requests" as={Link}>
                      <FaListUl className="me-2" size="1.5em" /> Заявки
                    </Nav.Link>
                  </Nav.Item>
                )}

                <Nav.Item>
                  <Nav.Link eventKey="/profile/groups" to="/profile/groups" as={Link}>
                    <FaAddressBook className="me-2" size="1.5em" /> Учебные группы
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="/profile/bookings" to="/profile/bookings" as={Link}>
                    <FaHistory className="me-2" size="1.5em" /> Бронирования
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="/profile/events" to="/profile/events" as={Link}>
                    <FaInfoCircle className="me-2" size="1.5em" /> Мероприятия
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link role="button" onClick={() => console.log('reset')}>
                    <FaRedo className="me-2" size="1.5em" /> Сбросить пароль
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link role="button" onClick={onLogout} style={{ color: '#dc3545' }}>
                    <FaSignOutAlt className="me-2" size="1.5em" /> Выйти
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <div className="col p-3 box-shadow bg-body rounded-1">{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
