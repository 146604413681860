import React from 'react';

import { Modal, Form, Row, Col, FloatingLabel } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormikSelect } from '../../FormikSelect';

const CreateModal = ({ themes, competentions, submitCreate, showCreate, modalCloseCreate }) => {
  const initialValues = {
    name: '',
    competention: -1,
    themes: [],
    availableInConstructor: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательное поле'),
    competention: Yup.number().moreThan(-1, 'Выберите компетенцию').required('Обязательное поле'),
    themes: Yup.array().min(1, 'Выберите тему').required('Выберите темы'),
    availableInConstructor: Yup.boolean().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление модуля</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="moduleName" className="mb-3">
              <Form.Label>Наименование *</Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="moduleCompetention" className="mb-3">
              <Form.Label>Компетенция *</Form.Label>
              <FormikSelect
                name="competention"
                value={formik.values.competention}
                form={formik}
                options={competentions.map((x) => ({ value: x.id, label: x.name }))}
                placeholder="Выберите компетенцию"
                isError={formik.errors.competention}
              />

              {formik.errors.competention && (
                <div className="invalid-feedback d-block">{formik.errors.competention}</div>
              )}

              {/* <Form.Select
                name="competention"
                onChange={formik.handleChange}
                value={formik.values.competention}
                isValid={formik.touched.competention && !formik.errors.competention}
                isInvalid={!!formik.errors.competention}>
                <option value="-1">Выберите компетенцию</option>

                {competentions.map((obj) => (
                  <option key={obj.id} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {formik.errors.competention}
              </Form.Control.Feedback> */}
            </Form.Group>
          </Row>

          <Row className="align-items-center">
            <Form.Group as={Col} controlId="moduleThemes" className="mb-3">
              <Form.Label>Темы *</Form.Label>
              <FormikSelect
                name="themes"
                value={formik.values.themes}
                form={formik}
                options={themes.map((x) => ({ value: x.id, label: x.name }))}
                isMulti
                placeholder="Выберите темы"
                isError={formik.errors.themes}
              />

              {formik.errors.themes && (
                <div className="invalid-feedback d-block">{formik.errors.themes}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} className="mt-3">
              <Form.Check
                type="switch"
                id="switch-availableInConstructor"
                name="availableInConstructor"
                onChange={formik.handleChange}
                value={formik.values.availableInConstructor}
                label="Доступно в конструкторе"
              />
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-success w-100 py-2">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
