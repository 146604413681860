import React from 'react';

import { Modal, Form, Row, Col, FloatingLabel } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateModal = ({ submitCreate, showCreate, modalCloseCreate, studyFormats }) => {
  const initialValues = {
    name: '',
    description: '',
    duration: 1,
    price: 0,
    format: -1,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Обязательно поле'),
    description: Yup.string(),
    duration: Yup.number().required('Обязательно поле'),
    price: Yup.number().required('Обязательно поле'),
    format: Yup.number().moreThan(-1, 'Выберите форму проведения').required('Обязательно поле'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление темы</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <div className="d-flex flex-wrap gap-3">
            <FloatingLabel as={Col} controlId="themesName" className="mb-3" label="Наименование *">
              <Form.Control
                placeholder="Наименование *"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel
              as={Col}
              controlId="themesFormat"
              className="mb-3"
              label="Форма проведения *">
              <Form.Select
                placeholder="Форма проведения *"
                name="format"
                onChange={formik.handleChange}
                value={formik.values.format}
                isValid={formik.touched.format && !formik.errors.format}
                isInvalid={!!formik.errors.format}>
                <option value="-1">Выберите форму проведения</option>
                {studyFormats.map((format) => (
                  <option key={format.id} value={format.id}>
                    {format.name}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback type="invalid">{formik.errors.format}</Form.Control.Feedback>
            </FloatingLabel>
          </div>

          <FloatingLabel controlId="themesDescription" className="mb-3" label="Содержание">
            <Form.Control
              placeholder="Содержание"
              as="textarea"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              isValid={formik.touched.description && !formik.errors.description}
              isInvalid={!!formik.errors.description}
              style={{ height: '100px' }}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </FloatingLabel>

          <div className="d-flex flex-wrap gap-3">
            <FloatingLabel
              as={Col}
              controlId="themesDuration"
              className="mb-3"
              label="Продолжительность в часах *">
              <Form.Control
                placeholder="Продолжительность в часах *"
                type="number"
                min="1"
                name="duration"
                onChange={formik.handleChange}
                value={formik.values.duration}
                isValid={formik.touched.duration && !formik.errors.duration}
                isInvalid={!!formik.errors.duration}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.duration}</Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel as={Col} controlId="themesPrice" className="mb-3" label="Цена *">
              <Form.Control
                placeholder="Цена *"
                name="price"
                onChange={formik.handleChange}
                value={formik.values.price}
                isValid={formik.touched.price && !formik.errors.price}
                isInvalid={!!formik.errors.price}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.price}</Form.Control.Feedback>
            </FloatingLabel>
          </div>

          <button type="submit" className="btn btn-success w-100 py-2">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
