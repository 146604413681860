import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { fetchUserUpdate } from '../../../redux/slices/authSlice';

import useAuth from '../../../hooks/useAuth';

import Profile from '../../../components/Layouts/Profile';

const PersonalData = () => {
  const dispatch = useDispatch();
  const { data, isUser, isAuth, isAdmin, isEmployer, isManager } = useAuth();

  const validationSchemaProfile = yup.object().shape({
    email: yup.string().required('Обязательное поле!').min(6, 'Минимальная длина 6'),
    // password: yup.string().required('Обязательное поле!').min(6, 'Минимальная длина 6'),
  });

  const initialValuesProfile = {
    id: data.user ? data.user.id : data.id,
    lastName: data.user ? data.user.lastName : data.lastName,
    firstName: data.user ? data.user.firstName : data.firstName,
    secondName: data.user ? data.user.secondName : data.secondName,
    email: data.user ? data.user.email : data.email,
    phone: data.user ? data.user.phone : data.phone,
    city: data.user ? data.user.city : data.city,
    region: data.user ? data.user.region : data.region,
    type: data.user
      ? data.user.type
        ? { id: data.user.type.id }
        : ''
      : data.type
      ? { id: data.type.id }
      : '',
    role: data.user ? { id: data.user.role.id } : data.role ? { id: data.role.id } : '',
    organization: data.user
      ? data.user.organization
        ? { id: data.user.organization.id }
        : ''
      : data.organization
      ? { id: data.organization }
      : '',
    birthdate: data.user
      ? `${data.user.birthdate[0]}-${
          data.user.birthdate[1] < 10 ? '0' + data.user.birthdate[1] : data.user.birthdate[1]
        }-${data.user.birthdate[2] < 10 ? '0' + data.user.birthdate[2] : data.user.birthdate[2]}`
      : `${data.birthdate[0]}-${
          data.birthdate[1] < 10 ? '0' + data.birthdate[1] : data.birthdate[1]
        }-${data.birthdate[2] < 10 ? '0' + data.birthdate[2] : data.birthdate[2]}` ?? '',
    sex: data.user ? data.user.sex : data.sex,
  };

  const formikProfile = useFormik({
    initialValues: initialValuesProfile,
    validationSchema: validationSchemaProfile,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(fetchUserUpdate(JSON.stringify(values)));
    },
  });

  React.useEffect(() => {
    document.title = 'ЦОПП - Личные данные';
  }, []);

  return (
    <Profile>
      <Form className="auth-form" onSubmit={formikProfile.handleSubmit} noValidate>
        <div className="d-flex gap-3 mb-3">
          <FloatingLabel controlId="profileLastname" label="Фамилия" as={Col}>
            <Form.Control
              placeholder="Фамилия"
              type="lastName"
              name="lastName"
              value={formikProfile.values.lastName || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.lastName && !formikProfile.errors.lastName}
              isInvalid={formikProfile.errors.lastName}
            />

            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.lastName}
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId="profileFirstname" label="Имя" as={Col}>
            <Form.Control
              placeholder="Имя"
              type="firstName"
              name="firstName"
              value={formikProfile.values.firstName || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.firstName && !formikProfile.errors.firstName}
              isInvalid={formikProfile.errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.firstName}
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel as={Col} controlId="profileSecondname" label="Отчество">
            <Form.Control
              placeholder="Отчество"
              type="secondName"
              name="secondName"
              value={formikProfile.values.secondName || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.secondName && !formikProfile.errors.secondName}
              isInvalid={formikProfile.errors.secondName}
            />

            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.secondName}
            </Form.Control.Feedback>
          </FloatingLabel>
        </div>

        <div className="d-flex gap-3 mb-3">
          <FloatingLabel as={Col} controlId="profileEmail" label="EMail">
            <Form.Control
              placeholder="EMail"
              type="email"
              name="email"
              value={formikProfile.values.email || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.email && !formikProfile.errors.email}
              isInvalid={formikProfile.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.email}
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel as={Col} controlId="profilePhone" label="Телефон">
            <Form.Control
              placeholder="Телефон"
              type="tel"
              name="phone"
              value={formikProfile.values.phone || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.phone && !formikProfile.errors.phone}
              isInvalid={formikProfile.errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.phone}
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel as={Col} controlId="profileBirthdate" label="Дата рождения">
            <Form.Control
              placeholder="Дата рождения"
              type="date"
              name="birthdate"
              value={formikProfile.values.birthdate || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.birthdate && !formikProfile.errors.birthdate}
              isInvalid={formikProfile.errors.birthdate}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.birthdate}
            </Form.Control.Feedback>
          </FloatingLabel>
        </div>

        <div className="d-flex gap-3 mb-3">
          <FloatingLabel as={Col} controlId="profileCity" label="Город">
            <Form.Control
              placeholder="Город"
              name="city"
              value={formikProfile.values.city || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.city && !formikProfile.errors.city}
              isInvalid={formikProfile.errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.city}
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel as={Col} controlId="profileRegion" label="Муниципальный район">
            <Form.Control
              placeholder="Муниципальный район"
              name="region"
              value={formikProfile.values.region || ''}
              onChange={formikProfile.handleChange}
              isValid={formikProfile.touched.region && !formikProfile.errors.region}
              isInvalid={formikProfile.errors.region}
            />
            <Form.Control.Feedback type="invalid">
              {formikProfile.errors.region}
            </Form.Control.Feedback>
          </FloatingLabel>
        </div>

        <Button variant="outline-success" type="submit" className="w-100 py-2">
          Сохранить изменения
        </Button>
      </Form>
    </Profile>
  );
};

export default PersonalData;
