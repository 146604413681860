import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';

import { useGetUserEventsQuery } from '../../services/profile';

import Profile from '../../components/Layouts/Profile';

import logo from '../../assets/img/logo.jpg';
import { ContentEmpty } from '../../components/ContentEmpty';

const ProfileEvents = () => {
  const { data: events } = useGetUserEventsQuery();

  return (
    <Profile>
      {events?.length > 0 ? (
        <div className="events profile">
          {events?.map((x) => (
            <div className="event-item" key={x.id}>
              <div className="event-img">
                <img
                  src={x?.image.src ? process.env.REACT_APP_API_URL + x.image.src : logo}
                  alt={x.name}
                />
              </div>

              <div className="event-content">
                <Link className="event-title" to={`/events/${x.id}`}>
                  {x.name}
                </Link>

                <div className="event-date">
                  <FaRegCalendarAlt /> Дата проведения:{' '}
                  {new Date(x.begin).toLocaleString().slice(0, -3)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ContentEmpty />
      )}
    </Profile>
  );
};

export default ProfileEvents;
