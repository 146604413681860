import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt, FaMinus } from 'react-icons/fa';

import {
  useGetModulesQuery,
  useCreateModuleMutation,
  useUpdateModuleMutation,
  useDeleteModuleMutation,
} from '../../../../services/modules';

import { useGetThemesQuery } from '../../../../services/themes';
import { useGetCompetentionsQuery } from '../../../../services/competentions';

import CreateModal from '../../../../components/Modals/Modules/Create';
import EditModal from '../../../../components/Modals/Modules/Edit';
import DeleteModal from '../../../../components/Modals/Modules/Delete';

import AlertMessage from '../../../../components/AlertMessage';

import useAuth from '../../../../hooks/useAuth';

import './Modules.scss';
import PaginationCustom from '../../../../components/PaginationCustom';

const Modules = () => {
  const { isAdmin } = useAuth();

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: modules, isLoading } = useGetModulesQuery({
    size: 20,
    page,
    role: isAdmin ? 'admin' : 'manager',
  });
  const [createModule, { isError: isErrorCreate }] = useCreateModuleMutation();
  const [updateModule, { isError: isErrorUpdate }] = useUpdateModuleMutation();
  const [deleteModule, { isError: isErrorDelete }] = useDeleteModuleMutation();

  const { data: themes } = useGetThemesQuery({
    size: 20000,
    page: 0,
    role: isAdmin ? 'admin' : 'manager',
  });
  const { data: competentions } = useGetCompetentionsQuery({ size: 200 });

  const { data } = useAuth();

  const [module, setModule] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.author = { id: data.id ? data.id : data.user.id };
    values.competention = { id: values.competention };
    values.themes = values.themes.map((x) => ({ id: x }));
    createModule({ role: isAdmin ? 'admin' : 'manager', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Модуль "${response.name}" успешно добавлен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить модуль.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setModule(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.competention = { id: values.competention };
    values.themes = values.themes.map((x) => ({ id: x }));
    updateModule({
      role: isAdmin ? 'admin' : 'manager',
      body: { id, author: { id: data.id ? data.id : data.user.id }, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Модуль "${response.name}" успешно изменен.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось отредактировать модуль. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });
    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setModule(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить модуль ${modules.content.find((x) => x.id === obj.id).name}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteModule({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Модуль "${modules.content.find((x) => x.id === response).name}" успешно удалён.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить модуль. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Модули';
  }, []);

  return (
    <>
      <CreateModal
        themes={themes?.content ?? []}
        competentions={competentions?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        module={module}
        themes={themes?.content ?? []}
        competentions={competentions?.content ?? []}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        id={module?.id}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-modules my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Модули</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
            Добавить модуль
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <div className="bg-body box-shadow p-3 rounded border border-light">
              <Table
                bordered
                responsive
                className={'text-center' + (modules?.content?.length < 20 ? ' mb-0' : '')}>
                <thead className="align-middle">
                  <tr>
                    {/* <th>№</th> */}
                    <th>Наименование модуля</th>
                    <th>Компетенция</th>
                    <th>Темы</th>
                    <th>Доступно в конструкторе</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {modules.content.map((obj) => (
                    <tr key={obj.id}>
                      {/* <td>{obj.id}</td> */}
                      <td>{obj?.name ?? ''}</td>
                      <td>
                        {obj.competentionName ? obj.competentionName : <FaMinus color="green" />}
                      </td>
                      <td>
                        {obj.themes?.map((theme) =>
                          theme?.name
                            ? theme.name + '; '
                            : themes?.content?.find((x) => x.id === theme)?.name + '; ',
                        )}
                      </td>
                      <td>{obj.availableInConstructor ? '✔' : '✖'}</td>
                      <td>
                        <Button variant="link" onClick={() => modalShowEdit(obj)}>
                          <FaEdit />
                        </Button>

                        <Button variant="link" onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <PaginationCustom totalPages={modules.totalPages} page={page} setPage={setPage} />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Modules;
