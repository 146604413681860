import React from 'react';

import { Modal, Form, Row, Col } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateModal = ({ submitCreate, showCreate, modalCloseCreate }) => {
  const initialValues = {
    name: '',
    inn: '',
    director: '',
    city: '',
    address: '',
    email: '',
    phone: '',
    region: '',
    managers: [],
    users: [],
    buildings: [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Обязательно поле'),
      inn: Yup.string().required('Обязательно поле'),
      director: Yup.string(),
      city: Yup.string().required('Обязательно поле'),
      address: Yup.string().required('Обязательно поле'),
      email: Yup.string().email().required('Обязательно поле'),
      phone: Yup.string().min(3),
      region: Yup.string().required('Обязательно поле'),
    }),
    onSubmit: (values, { resetForm }) => {
      submitCreate(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showCreate}
      onHide={modalCloseCreate}
      backdrop="static"
      keyboard={false}
      centered
      size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Добавление организации</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Row>
            <Form.Group as={Col} controlId="organizationName" className="mb-3">
              <Form.Label>
                Наименование <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={!!formik.errors.name}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="organizationINN" className="mb-3">
              <Form.Label>
                ИНН <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="inn"
                onChange={formik.handleChange}
                value={formik.values.inn}
                isValid={formik.touched.inn && !formik.errors.inn}
                isInvalid={!!formik.errors.inn}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.inn}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="organizationDirector" className="mb-3">
              <Form.Label>
                Руководитель <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="director"
                onChange={formik.handleChange}
                value={formik.values.director}
                isValid={formik.touched.director && !formik.errors.director}
                isInvalid={!!formik.errors.director}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.director}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="organizationCity" className="mb-3">
              <Form.Label>
                Город <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                isValid={formik.touched.city && !formik.errors.city}
                isInvalid={!!formik.errors.city}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="organizationAddress" className="mb-3">
              <Form.Label>
                Адрес <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                isValid={formik.touched.address && !formik.errors.address}
                isInvalid={!!formik.errors.address}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="region" className="mb-3">
              <Form.Label>
                Муниципальный район <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                autoComplete="off"
                name="region"
                value={formik.values.region}
                onChange={formik.handleChange}
                isValid={formik.touched.region && !formik.errors.region}
                isInvalid={!!formik.errors.region}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.region}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="organizationPhone" className="mb-3">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={!!formik.errors.phone}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="organizationEmail" className="mb-3">
              <Form.Label>
                Электронная почта <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={formik.touched.email && !formik.errors.email}
                isInvalid={!!formik.errors.email}
              />

              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <button type="submit" className="btn btn-outline-success w-100 py-2">
            Добавить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
