import React from 'react';

import { useGetBookingByUserQuery } from '../../services/booking';

import Profile from '../../components/Layouts/Profile';
import BookingItem from '../../components/Booking';
import { ContentEmpty } from '../../components/ContentEmpty';

const ProfileBookings = () => {
  const { data: booking } = useGetBookingByUserQuery({ size: 20, page: 0 });

  return (
    <Profile>
      {booking?.content.length > 0 ? (
        <div className="booking">
          {booking?.content?.map((obj) => (
            <BookingItem key={obj.id} {...obj} isProfile={true} />
          ))}
        </div>
      ) : (
        <ContentEmpty />
      )}
    </Profile>
  );
};

export default ProfileBookings;
