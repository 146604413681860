import React from 'react';

import { Table, Button } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} from '../../../../services/roles';

import CreateModal from '../../../../components/Modals/Roles/Create';
import EditModal from '../../../../components/Modals/Roles/Edit';
import DeleteModal from '../../../../components/Modals/Roles/Delete';

import AlertMessage from '../../../../components/AlertMessage';

import './Roles.scss';

const Roles = () => {
  const { data: roles, isLoading } = useGetRolesQuery();
  const [createRole, { isError: isErrorCreate }] = useCreateRoleMutation();
  const [updateRole, { isError: isErrorUpdate }] = useUpdateRoleMutation();
  const [deleteRole, { isError: isErrorDelete }] = useDeleteRoleMutation();

  const [role, setRole] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createRole(values)
      .unwrap()
      .then((response) => {
        setMessage(`Роль "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить роль. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setRole(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateRole({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Роль "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить роль. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setRole(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить роль ${roles.content.find((x) => x.id === obj.id).name}?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteRole(id)
      .unwrap()
      .then((response) => {
        setMessage(`Роль "${roles.content.find((x) => x.id === response).name}" успешно удалена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить роль. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Роли';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        role={role}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        role={role}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-roles my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Роли</h1>
            <hr />

            <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
              Добавить роль
            </Button>
          </div>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <div className="bg-body box-shadow p-3 rounded-1 border border-light">
              <Table bordered responsive className="text-center mb-0">
                <thead className="align-middle">
                  <tr>
                    <th>Название</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {roles.content.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>
                        <Button variant="link" onClick={() => modalShowEdit(obj)}>
                          <FaEdit />
                        </Button>

                        <Button variant="link" onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Roles;
