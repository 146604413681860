import useAuth from '../../../hooks/useAuth';

import EmployerResumes from './Employer';
import UserResumes from './User';

const ProfileResumes = () => {
  const { isUser, isEmployer } = useAuth();

  return (
    <>
      {isUser && <UserResumes />}
      {isEmployer && <EmployerResumes />}
    </>
  );
};

export default ProfileResumes;
