import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import {
  useUpdateRequestOrganizationMutation,
  useGetRequestsQuery,
} from '../../services/organizations';

import AlertMessage from '../../components/AlertMessage';

const OrganisationRequests = () => {
  const { data: requests, isLoading } = useGetRequestsQuery();

  const [updateRequest, { isError: isErrorAccept }] = useUpdateRequestOrganizationMutation();

  const [message, setMessage] = React.useState(null);

  const [modal, setModal] = React.useState({ type: '', show: false, id: '' });
  const handleClose = ({ type, id }) => setModal({ type, show: false, id });
  const handleShow = ({ type, id }) => setModal({ type, show: true, id });
  const handleSubmit = ({ type, id }) => {
    handleClose({ type, id });

    updateRequest({ id, status: type === 'accept' ? 'NOT_ACTIVE' : 'DELETED' })
      .unwrap()
      .then(() => {
        setMessage(`Заявка успешно ${type === 'accept' ? 'принята' : 'отклонена'}.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось ${type === 'accept' ? 'принять' : 'отклонить'} заявку. ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });
  };

  return (
    <>
      {isLoading ? (
        <h1 className="text-center">Загрузка...</h1>
      ) : (
        <section className="section section-organization-requests my-5">
          <div className="container">
            <div className="section-title ">
              <h1>Заявки на вступление в организацию</h1>
              <hr />
            </div>

            {message && (
              <AlertMessage message={message} setMessage={setMessage} isError={isErrorAccept} />
            )}

            {requests && (
              <div className="org-requests row">
                {requests?.content?.map((request) => (
                  <div
                    className="col-4 requests-item box-shadow bg-body rounded-1 p-3"
                    key={request?.id}>
                    <h5 className="requests-item__fullname mb-2">
                      {request?.userDto?.lastName} {request?.userDto?.firstName}
                      {request?.userDto?.secondName ? ` ${request?.userDto?.secondName}` : ''}
                    </h5>
                    <p className="requests-item__email mb-0">
                      <span className="text-uppercase text-secondary">EMail: </span>
                      {request?.userDto?.email}
                    </p>
                    <p className="requests-item__phone mb-0">
                      <span className="text-uppercase text-secondary">Телефон: </span>
                      {request?.userDto?.phone}
                    </p>
                    <p className="requests-item__phone mb-0">
                      <span className="text-uppercase text-secondary">Организация: </span>
                      {request?.organizationDTO?.name}
                    </p>

                    <div className="d-flex gap-2 mt-2">
                      <button
                        className="col btn btn-danger"
                        onClick={() => handleShow({ type: 'reject', id: request?.id })}>
                        Отклонить
                      </button>
                      <button
                        className="col btn btn-success"
                        onClick={() => handleShow({ type: 'accept', id: request?.id })}>
                        Принять
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      )}

      <Modal
        show={modal.show}
        onHide={() => handleClose({ type: modal.type, id: modal?.id })}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Подтверждение</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Вы действительно хотите {modal.type === 'accept' ? 'принять' : 'отклонить'} зявление?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleClose({ type: modal.type, id: modal?.id })}>
            Отмена
          </Button>
          <Button
            variant="success"
            onClick={() => handleSubmit({ type: modal.type, id: modal?.id })}>
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrganisationRequests;
