import React from 'react';

import Profile from '../../../components/Layouts/Profile';
import CreateModalResume from '../../../components/Modals/Resume/Create';
import EditModalResume from '../../../components/Modals/Resume/Edit';
import DeleteModalResume from '../../../components/Modals/Resume/Delete';
import AlertMessage from '../../../components/AlertMessage';

import {
  useCreateResumeMutation,
  useDeleteResumeMutation,
  useGetResumesQuery,
  useUpdateResumeMutation,
} from '../../../services/users';

const UserResumes = () => {
  const { data: resumes, isLoadingResumes } = useGetResumesQuery();

  const [message, setMessage] = React.useState(null);

  const [createResume, { isError: isErrorCreateResume }] = useCreateResumeMutation();
  const [updateResume, { isError: isErrorUpdateResume }] = useUpdateResumeMutation();
  const [deleteResume, { isError: isErrorDeleteResume }] = useDeleteResumeMutation();

  const [resume, setResume] = React.useState({});
  const [showCreateResume, setShowCreateResume] = React.useState(false);
  const [showEditResume, setShowEditResume] = React.useState(false);
  const [showDeleteResume, setShowDeleteResume] = React.useState(false);

  const modalCloseCreateResume = () => setShowCreateResume(false);
  const modalShowCreateResume = () => setShowCreateResume(true);
  const submitCreateResume = (values) => {
    createResume({ ...values, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`Резюме успешно добавлено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить резюме. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreateResume();
  };

  const modalCloseEditResume = () => setShowEditResume(false);
  const modalShowEditResume = (obj) => {
    setResume(obj);
    setShowEditResume(true);
  };
  const submitEditResume = (id, values) => {
    updateResume({ id, ...values, status: 'ACTIVE' })
      .unwrap()
      .then((response) => {
        setMessage(`Резюме успешно изменено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить резюме.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEditResume();
  };

  const modalCloseDeleteResume = () => setShowDeleteResume(false);
  const modalShowDeleteResume = (obj) => {
    setResume(obj);
    setShowDeleteResume(true);
  };
  const submitDeleteResume = (id) => {
    deleteResume(id)
      .unwrap()
      .then((response) => {
        setMessage('Резюме успешно удалено.');
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить резюме.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDeleteResume();
  };

  return (
    <>
      <CreateModalResume
        submitCreate={submitCreateResume}
        showCreate={showCreateResume}
        modalCloseCreate={modalCloseCreateResume}
      />

      <EditModalResume
        resume={resume}
        showEdit={showEditResume}
        submitEdit={submitEditResume}
        modalCloseEdit={modalCloseEditResume}
      />

      <DeleteModalResume
        resume={resume}
        showDelete={showDeleteResume}
        modalCloseDelete={modalCloseDeleteResume}
        submitDelete={submitDeleteResume}
      />

      <Profile>
        {message && (
          <AlertMessage
            message={message}
            setMessage={setMessage}
            isError={isErrorCreateResume || isErrorUpdateResume || isErrorDeleteResume}
          />
        )}

        <button className="btn btn-outline-primary w-100 py-2" onClick={modalShowCreateResume}>
          Добавить резюме
        </button>

        {!isLoadingResumes && (
          <div className="resumes mb-3">
            {resumes?.content?.map((resume) => (
              <div className="resumes-item" key={resume.id}>
                <div className="resumes-item__position">
                  Резюме{' '}
                  {resume?.educations
                    .map((education) => education.name + ' / ' + education.organization)
                    .join(', ')}
                </div>

                <div className="resumes-item__btns">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => modalShowEditResume(resume)}>
                    Редактировать
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => modalShowDeleteResume(resume)}>
                    Удалить
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Profile>
    </>
  );
};

export default UserResumes;
