import React from 'react';

import { Table, Button, Alert } from 'react-bootstrap';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetStudyFormatsQuery,
  useCreateStudyFormatMutation,
  useUpdateStudyFormatMutation,
  useDeleteStudyFormatMutation,
} from '../../../../services/studyFormats';

import CreateModal from '../../../../components/Modals/StudyFormats/Create';
import DeleteModal from '../../../../components/Modals/StudyFormats/Delete';
import EditModal from '../../../../components/Modals/StudyFormats/Edit';

import './StudyFormats.scss';

const StudyFormats = () => {
  const { data: studyFormats, isLoading } = useGetStudyFormatsQuery();
  const [createStudyFormat, { isError: isErrorCreate }] = useCreateStudyFormatMutation();
  const [updateStudyFormat, { isError: isErrorUpdate }] = useUpdateStudyFormatMutation();
  const [deleteStudyFormat, { isError: isErrorDelete }] = useDeleteStudyFormatMutation();

  const [format, setFormat] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createStudyFormat(values)
      .unwrap()
      .then((response) => {
        setMessage(`Форма проведения "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить форму проведения.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (format) => {
    setFormat(format);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateStudyFormat({ id, ...values })
      .unwrap()
      .then((response) => {
        setMessage(`Форма проведения "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить форму проведения.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (format) => {
    setFormat(format);
    setDeleteMessage(
      `Вы уверены, что хотите удалить форму проведения ${
        studyFormats.content.find((x) => x.id === format.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteStudyFormat(id)
      .unwrap()
      .then((response) => {
        setMessage(
          `Форма проведения "${
            studyFormats.content.find((x) => x.id === response).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить форму проведения.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });
    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Формы проведения';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        format={format}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        id={format?.id}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-study-formats my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Формы проведения</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
            Добавить форму проведения
          </Button>

          {message && (
            <Alert
              variant={isErrorCreate || isErrorUpdate || isErrorDelete ? 'danger' : 'success'}
              className="text-center"
              onClose={() => setMessage(null)}
              dismissible>
              {message}
            </Alert>
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <div className="bg-body box-shadow p-3 rounded-1 border border-light">
              <Table bordered responsive className="text-center mb-0">
                <thead className="align-middle">
                  <tr>
                    <th>Наименование</th>
                    <th>Действия</th>
                  </tr>
                </thead>

                <tbody className="align-middle">
                  {studyFormats.content.map((obj) => (
                    <tr key={obj.id}>
                      <td>{obj.name}</td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowEdit(obj)}>
                          <FaEdit size="1.1em" />
                        </Button>

                        <Button
                          variant="link"
                          className="p-0 mx-2"
                          onClick={() => modalShowDelete(obj)}>
                          <FaTrashAlt size="1.1em" color="red" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default StudyFormats;
