import React from 'react';
import { Link, useParams } from 'react-router-dom';

import EducationModuleItem from '../../../components/Education/Module';
import LoaderEducationModules from '../../../components/Loaders/EducationModules';
import SelectModules from '../../../components/Modals/SelectModules';

import { useGetModulesConstructorQuery } from '../../../services/constructor';

import './EducationModule.scss';

const EducationModule = () => {
  const { slug_skill, slug_module } = useParams();

  console.log(slug_skill, slug_module);

  const { data: modules, isLoading } = useGetModulesConstructorQuery({
    object: slug_skill,
    competence: slug_module,
  });

  const [show, setShow] = React.useState(false);
  const onModalOpen = () => setShow(true);

  return (
    <>
      <SelectModules show={show} setShow={setShow} />

      <section className="section section-module my-5">
        <div className="container">
          <div className="section-title">
            <h1>Заявка на обучение</h1>
            <hr />
          </div>

          <div className="modules-wrapper">
            <div className="modules-btns">
              <Link className="modules-btn back" to={`/educations/${slug_skill}`}>
                Вернуться назад
              </Link>
              <button className="modules-btn request" onClick={onModalOpen}>
                Сформировать запрос
              </button>
            </div>

            <div className="modules">
              {isLoading
                ? [...new Array(6)].map((_, index) => <LoaderEducationModules key={index} />)
                : modules?.map((obj) => <EducationModuleItem key={obj?.id} {...obj} />)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EducationModule;
