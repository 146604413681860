import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import './App.scss';

import Footer from './components/Footer';
import Header from './components/Header';

import Home from './pages/Home';

import SuccessStory from './pages/SuccessStory';
import SuccessStoryDetail from './pages/SuccessStory/Detail';

import Education from './pages/Education';
import EducationSkill from './pages/Education/EducationSkill';
import EducationModule from './pages/Education/EducationModule';
import Programs from './pages/Programs';
import Events from './pages/Events';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Registration from './pages/Registration';
import NotFound from './pages/NotFound';
import ProgramsDetail from './pages/Programs/Detail';
import EventsDetail from './pages/Events/Detail';
import Booking from './pages/Booking';
import BookingDetail from './pages/Booking/Detail';
import Teaching from './pages/Teaching';

import NewsDetail from './pages/News/Detail';
import Profile from './pages/Profile';

import TimetableLessons from './pages/Admin/Planning/TimetableLessons';
import Modules from './pages/Admin/Planning/Modules';
import Themes from './pages/Admin/Planning/Themes';
import StudyGroups from './pages/Admin/Planning/Groups';

import Teachers from './pages/Admin/Accounts/Teachers';
import Employers from './pages/Admin/Accounts/Employers';
import Users from './pages/Admin/Accounts/Users';
import Roles from './pages/Admin/Accounts/Roles';
import UserTypes from './pages/Admin/Accounts/UserTypes';

import RequestsConstructor from './pages/Admin/Requests/Constructor';
import RequestsProgram from './pages/Admin/Requests/Program';
import RequestsTeaching from './pages/Admin/Requests/Teaching';
import RequestsRecruiting from './pages/Admin/Requests/Recruiting';
import RequestsBooking from './pages/Admin/Requests/Booking';

import Organizations from './pages/Organizations';
import Managers from './pages/Admin/Organization/Managers';
import Buildings from './pages/Admin/Organization/Buildings';
import Equipments from './pages/Admin/Organization/Equipments';
import Rooms from './pages/Admin/Organization/Rooms';

import ProgramTypes from './pages/Admin/Other/ProgramTypes';
import ProgramSubspecies from './pages/Admin/Other/ProgramSubspecies';
import TargetGroups from './pages/Admin/Other/TargetGroups';
import StudyFormats from './pages/Admin/Other/StudyFormats';
import Competentions from './pages/Admin/Other/Competentions';
import EventTypes from './pages/Admin/Other/EventTypes';
import ProfessionalObjects from './pages/Admin/Other/ProfessionalObjects';
import RoomTypes from './pages/Admin/Other/RoomTypes';

import Recruiting from './pages/Recruiting';
import ProgramsAdmin from './pages/Admin/Planning/Programs';
import Vacancy from './pages/Vacancy';
import VacancyDetail from './pages/Vacancy/Detail';

import { useDispatch } from 'react-redux';
import { fetchUser } from './redux/slices/authSlice';

import ProtectedRoute from './components/ProtectedRoute';
import useAuth from './hooks/useAuth';
import ResumeById from './pages/Profile/Resumes/ById';
import EditResume from './pages/Profile/Resumes/EditResume';
import Cities from './pages/Admin/Other/Cities';
import Regions from './pages/Admin/Other/Regions';
import Resumes from './pages/Resumes';
import Responses from './pages/Responses';
import RequestRecruitingById from './pages/Admin/Requests/Recruiting/ById';
import OrganisationRequests from './pages/OrganisationRequests';
import PersonalData from './pages/Profile/PersonalData';
import UserResumes from './pages/Profile/Resumes/User';
import ProfileResumes from './pages/Profile/Resumes';
import ProfileOrganization from './pages/Profile/ProfileOrganization';
import ProfileRequests from './pages/Profile/ProfileRequests';
import ProfileGroups from './pages/Profile/ProfileGroups';
import ProfileBookings from './pages/Profile/ProfileBookings';
import ProfileEvents from './pages/Profile/ProfileEvents';

const App = () => {
  const dispatch = useDispatch();
  const { isAuth, isUser, isAdmin, isManager, isEmployer, isLoading } = useAuth();

  React.useEffect(() => {
    if (localStorage.getItem('accessToken')) dispatch(fetchUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && localStorage.getItem('accessToken')) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" style={{ width: '4em', height: '4em' }} />
      </div>
    );
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/vacancy" element={<Vacancy />} />
        <Route path="/vacancy/:id" element={<VacancyDetail />} />

        <Route path="/success-story" element={<SuccessStory />} />
        <Route path="/success-story/:id" element={<SuccessStoryDetail />} />

        <Route path="/news/:id" element={<NewsDetail />} />

        <Route path="/educations" element={<Education />} />
        <Route path="/educations/:slug_skill" element={<EducationSkill />} />
        <Route path="/educations/:slug_skill/:slug_module" element={<EducationModule />} />

        <Route path="/programs" element={<Programs />} />
        <Route path="/programs/:id" element={<ProgramsDetail />} />

        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<EventsDetail />} />

        <Route element={<ProtectedRoute isAllowed={isAuth} />}>
          <Route path="/booking" element={<Booking />} />
          <Route path="/booking/:id" element={<BookingDetail />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/registration" element={<Registration />} />

        <Route element={<ProtectedRoute isAllowed={isAuth} />}>
          <Route path="/recruiting" element={<Recruiting />} />
          <Route path="/teaching" element={<Teaching />} />
          <Route path="/profile" element={<PersonalData />} />
          <Route path="/profile/resumes" element={<ProfileResumes />} />
          <Route path="/profile/organization" element={<ProfileOrganization />} />
          <Route path="/profile/requests" element={<ProfileRequests />} />
          <Route path="/profile/groups" element={<ProfileGroups />} />
          <Route path="/profile/bookings" element={<ProfileBookings />} />
          <Route path="/profile/events" element={<ProfileEvents />} />

          <Route path="/resumes/:id" element={<ResumeById />} />
          <Route path="/resumes/:id/edit" element={<EditResume />} />
        </Route>

        {/* Admin routes */}
        <Route element={<ProtectedRoute isAllowed={isAdmin} />}>
          {/* Планирование */}
          <Route path="/timetable-lessons" element={<TimetableLessons />} />

          {/* Аккаунты */}
          <Route path="/users" element={<Users />} />
          <Route path="/employers" element={<Employers />} />
          <Route path="/user-types" element={<UserTypes />} />
          <Route path="/roles" element={<Roles />} />

          {/* Запросы */}
          <Route path="/requests-constructor" element={<RequestsConstructor />} />
          <Route path="/requests-program" element={<RequestsProgram />} />
          <Route path="/requests-teaching" element={<RequestsTeaching />} />
          <Route path="/requests-recruiting" element={<RequestsRecruiting />} />
          <Route path="/requests-recruiting/:id" element={<RequestRecruitingById />} />
          <Route path="/requests-booking" element={<RequestsBooking />} />

          {/* Организации */}
          <Route path="/managers" element={<Managers />} />

          <Route path="/program-types/" element={<ProgramTypes />} />
          <Route path="/program-subspecies/" element={<ProgramSubspecies />} />
          <Route path="/target-groups/" element={<TargetGroups />} />
          <Route path="/study-formats/" element={<StudyFormats />} />
          <Route path="/professional-objects/" element={<ProfessionalObjects />} />
          <Route path="/competentions/" element={<Competentions />} />
          <Route path="/event-types" element={<EventTypes />} />
          <Route path="/room-types/" element={<RoomTypes />} />

          {/* <Route path="/cities/" element={<Cities />} /> */}
          <Route path="/regions/" element={<Regions />} />
        </Route>

        {/* Admin or Manager routes*/}
        <Route element={<ProtectedRoute isAllowed={isAdmin || isManager} />}>
          {/* Планирование */}
          <Route path="/modules" element={<Modules />} />
          <Route path="/themes" element={<Themes />} />
          <Route path="/study-groups" element={<StudyGroups />} />

          <Route path="/programs-table" element={<ProgramsAdmin />} />

          {/* Аккаунты */}
          <Route path="/teachers" element={<Teachers />} />

          {/* Организации */}
          <Route path="/buildings" element={<Buildings />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/equipments" element={<Equipments />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={isManager} />}>
          {/* Заявки на вступление в организацию */}
          <Route path="/org-users" element={<OrganisationRequests />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={isAdmin || isManager || isUser} />}>
          {/* Организации */}
          <Route path="/organizations" element={<Organizations />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={isUser || isEmployer || isManager} />}>
          {/* Резюме */}
          <Route path="/resumes" element={<Resumes />} />
          {/* Отклики */}
          <Route path="/responses" element={<Responses />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
};

export default App;
