import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetSuccessByIdQuery } from '../../../services/successStory';

import NotFound from '../../../pages/NotFound';

import LoaderNewsDetail from '../../../components/Loaders/NewsDetail';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

import '@fancyapps/ui/dist/fancybox.esm.js';
import '@fancyapps/ui/dist/fancybox.css';

import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

import './SuccessStoryDetail.scss';
import { PhotoViewIcon } from '../../../components/PhotoViewIcon';

const SuccessStoryDetail = () => {
  const { id } = useParams();

  const { data: success, isLoading } = useGetSuccessByIdQuery(id);

  React.useEffect(() => {
    document.title = 'ЦОПП - Просмотр истории успеха';
    window.scroll(0, 0);
  }, []);

  if (success === null) {
    return <NotFound />;
  }

  return (
    <div className="container">
      <div className="success-story-detail__wrapper my-5">
        {isLoading ? (
          <LoaderNewsDetail />
        ) : (
          <>
            <div className="success-story-detail">
              <h4 className="success-story-title">{success?.name}</h4>
              <hr />
              <div className="success-story-date">
                {new Date(success?.created).toLocaleDateString()}
              </div>
              <div className="success-story-content">{success?.text}</div>
            </div>

            {success?.image && (
              <div className="success-story-img detail">
                <a href={process.env.REACT_APP_API_URL + success?.image.src} data-fancybox>
                  <img
                    src={process.env.REACT_APP_API_URL + success?.image.src}
                    alt={success?.name}
                  />

                  <PhotoViewIcon />
                </a>
              </div>
            )}

            {success?.images && (
              <div className="mt-4">
                <Swiper
                  modules={[Navigation]}
                  navigation={true}
                  slidesPerView={3}
                  spaceBetween={20}
                  autoHeight={true}>
                  {success?.images.map((x) => (
                    <SwiperSlide key={x.id}>
                      <a href={process.env.REACT_APP_API_URL + x.src} data-fancybox>
                        <img src={process.env.REACT_APP_API_URL + x.src} alt={x.name} />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}

            {success?.videoSrc && (
              <Plyr
                source={{
                  type: 'video',
                  sources: [
                    {
                      src: success.videoSrc,
                      provider: 'youtube',
                    },
                  ],
                }}
                options={{
                  quality: {
                    default: 1080,
                    options: [2160, 1440, 1080, 720, 480, 360],
                  },
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessStoryDetail;
