import React from 'react';
import { Button } from 'react-bootstrap';

import { useGetUserRequestsQuery } from '../../services/profile';
import {
  useGetEmployeeRequestsQuery,
  useUpdateEmployeeRequestsMutation,
} from '../../services/requests';

import Profile from '../../components/Layouts/Profile';
import useAuth from '../../hooks/useAuth';

import typeRequest from '../../typeRequest';
import STATUS from '../../statusRequest';
import AlertMessage from '../../components/AlertMessage';
import EditRecruiting from '../../components/Modals/Requests/EditRecruiting';
import { ContentEmpty } from '../../components/ContentEmpty';

const ProfileRequests = () => {
  const { isAdmin, isEmployer } = useAuth();

  const { data: requests, isLoadingRequests } = useGetUserRequestsQuery();
  const { data: employeeRequests, isLoadingEmployeeRequests } = useGetEmployeeRequestsQuery({
    role: isAdmin ? 'admin' : isEmployer ? 'employer' : 'user',
    page: 0,
  });

  const [message, setMessage] = React.useState(null);
  const [showEditRequest, setShowEditRequest] = React.useState(false);

  const [request, setRequest] = React.useState({});
  const [updateRequest, { isError: isErrorUpdateRequest }] = useUpdateEmployeeRequestsMutation();

  const modalCloseEditRequest = () => setShowEditRequest(false);
  const modalShowEditRequest = (obj) => {
    setRequest(obj);
    setShowEditRequest(true);
  };
  const submitEditRequest = (id, values) => {
    values.organization = { id: values.organization };

    updateRequest({ role: isAdmin ? 'admin' : 'employer', id, body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(`Заявка на подбор персонала успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось изменить заявку на подбор персонала.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEditRequest();
  };

  const allRequests = [...(requests?.content ?? []), ...(employeeRequests?.content ?? [])];

  return (
    <Profile>
      {message && (
        <AlertMessage message={message} setMessage={setMessage} isError={isErrorUpdateRequest} />
      )}

      {isEmployer && (
        <EditRecruiting
          request={request}
          showEdit={showEditRequest}
          submitEdit={submitEditRequest}
          modalCloseEdit={modalCloseEditRequest}
        />
      )}

      {allRequests.length > 0 ? (
        <div className="requests">
          {allRequests?.map((x) => (
            <div
              className="p-3 text-center rounded-1 box-shadow bg-body border border-light"
              key={x.id}>
              <p className="text-uppercase fw-bold">
                {x.requestFrom
                  ? `Откуда: ${typeRequest[x.requestFrom]}`
                  : 'Заявка на подбор персонала'}
              </p>

              {!x.requestFrom && (
                <p className="request-item__position mb-0">
                  <span className="text-uppercase text-secondary">Должность: </span>
                  {x.position}
                </p>
              )}

              <p className="request-item__status mb-0">
                <span className="text-uppercase text-secondary">Статус: </span>
                {STATUS[x.requestStatus]}
              </p>

              <p className="request-item__date mb-0">
                <span className="text-uppercase text-secondary">Дата: </span>
                {new Date(x?.created).toLocaleDateString()}
              </p>

              {!x.requestFrom && x.requestStatus === 'WAITING' && (
                <div className="request-item__btns mt-3">
                  <Button
                    variant="outline-primary"
                    className="btn btn-outline-primary w-100 py-2"
                    onClick={() => modalShowEditRequest(x)}>
                    Редактировать
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <ContentEmpty />
      )}
    </Profile>
  );
};

export default ProfileRequests;
