import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const constructorAPI = createApi({
  reducerPath: 'constructorAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Constructor'],
  endpoints: (builder) => ({
    getConstructor: builder.query({
      query: (type = 'Constructor') =>
        type === 'Constructor' ? `/constructor/` : `/services/professional-objects/`,
      providesTags: () => ['Constructor'],
    }),
    getSkillsConstructor: builder.query({
      query: (object) => `/constructor/${object}`,
      providesTags: () => ['Constructor'],
    }),
    getModulesConstructor: builder.query({
      query: (params) => `/constructor/${params.object}/${params.competence}`,
      providesTags: () => ['Constructor'],
    }),
  }),
});

export const {
  useGetConstructorQuery,
  useGetSkillsConstructorQuery,
  useGetModulesConstructorQuery,
} = constructorAPI;
