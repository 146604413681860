import React from 'react';
import { Link, useParams } from 'react-router-dom';

import EducationSkillItem from '../../../components/Education/Skill';
import LoaderEducationSkills from '../../../components/Loaders/EducationSkills';
import SelectModules from '../../../components/Modals/SelectModules';

import { useGetSkillsConstructorQuery } from '../../../services/constructor';

import './EducationSkill.scss';

const EducationSkill = () => {
  const { slug_skill } = useParams();

  const { data: skills, isLoading } = useGetSkillsConstructorQuery(slug_skill);

  const [show, setShow] = React.useState(false);
  const onModalOpen = () => setShow(true);

  return (
    <>
      <SelectModules show={show} setShow={setShow} />

      <section className="section section-skill my-5">
        <div className="container">
          <div className="section-title">
            <h1>Заявка на обучение</h1>
            <hr />
          </div>

          <div className="skills-wrapper">
            <div className="skills-btns">
              <Link className="skills-btn back" to="/educations">
                Вернуться назад
              </Link>

              <button className="skills-btn request" onClick={onModalOpen}>
                Сформировать запрос
              </button>
            </div>

            <div className="skills">
              {isLoading
                ? [...new Array(6)].map((_, index) => <LoaderEducationSkills key={index} />)
                : skills.map((obj) => <EducationSkillItem key={obj.id} {...obj} />)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EducationSkill;
