import React from 'react';

export const ContentEmpty = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100 text-center">
      <span style={{ fontSize: '5rem' }}>&#128533;</span>
      <p className="mb-0 h4">Нет данных для отображения</p>
    </div>
  );
};
