import React from 'react';

import { Modal, Form, FloatingLabel } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const Feedback = ({ submitFeedback, showFeedback, modalCloseFeedback }) => {
  const initialValues = {
    name: '',
    contact: '',
    text: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Укажите ФИО'),
    contact: Yup.string().required('Укажите контактные данные'),
    text: Yup.string().required('Укажите текст'),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      submitFeedback(values);
      resetForm({});
    },
  });

  return (
    <Modal
      show={showFeedback}
      onHide={modalCloseFeedback}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Форма обратной связи</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <FloatingLabel controlId="feedbackName" className="mb-3" label="ФИО *">
            <Form.Control
              placeholder="ФИО *"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              isValid={formik.touched.name && !formik.errors.name}
              isInvalid={!!formik.errors.name}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId="feedbackContact" className="mb-3" label="EMail или телефон *">
            <Form.Control
              placeholder="EMail или телефон *"
              name="contact"
              onChange={formik.handleChange}
              value={formik.values.contact}
              isValid={formik.touched.contact && !formik.errors.contact}
              isInvalid={!!formik.errors.contact}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.contact}</Form.Control.Feedback>
          </FloatingLabel>

          <Form.Group controlId="feedbackText" className="mb-3" label="Текст *">
            <Form.Control
              placeholder="Текст *"
              as="textarea"
              name="text"
              onChange={formik.handleChange}
              value={formik.values.text}
              isValid={formik.touched.text && !formik.errors.text}
              isInvalid={!!formik.errors.text}
              style={{ height: '100px' }}
            />

            <Form.Control.Feedback type="invalid">{formik.errors.text}</Form.Control.Feedback>
          </Form.Group>

          <button type="submit" className="btn btn-success w-100 py-2">
            Отправить
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Feedback;
