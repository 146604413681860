import { Container, Row, Col } from 'react-bootstrap';

import { FaVk, FaFacebookF, FaInstagram } from 'react-icons/fa';

import logoCopp from '../../assets/img/logo.jpg';
import logoKuzbass from '../../assets/img/logo_kuzbass.png';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col className="text-center">
            <div className="mb-3">
              <p>Телефон поддержки</p>
              <p>
                <a href="tel:73842571120" className="link-light">
                  +7 (3842) 57-11-20
                </a>
              </p>
            </div>
            <div className="mb-3">
              <p>EMail</p>
              <p>
                <a href="mailto:copp42@yandex.ru" className="link-light">
                  copp42@yandex.ru
                </a>
              </p>
            </div>
          </Col>
          <Col className="text-center">
            <img src={logoKuzbass} alt="" className="logo-kuzbass" />
          </Col>
          <Col className="text-center">
            <img src={logoCopp} alt="" className="logo-copp" />
          </Col>
          <Col className="text-center">
            <p className="mb-3">Соц. сети</p>
            <a href="https://vk.com/copp42kuzbass" target="_blank" className="footer-btn vk">
              <FaVk />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
