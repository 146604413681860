import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

import {
  useGetBuildingsQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
} from '../../../../services/buildings';

import { useGetOrganizationsQuery } from '../../../../services/organizations';

import CreateModal from '../../../../components/Modals/Buildings/Create';
import EditModal from '../../../../components/Modals/Buildings/Edit';
import DeleteModal from '../../../../components/Modals/Buildings/Delete';

import PaginationCustom from '../../../../components/PaginationCustom';
import AlertMessage from '../../../../components/AlertMessage';

import useAuth from '../../../../hooks/useAuth';

import './Buildings.scss';

const Buildings = () => {
  const { isAdmin, isManager } = useAuth();
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const { data: buildings, isLoading } = useGetBuildingsQuery({
    size: 20,
    page,
    role: isAdmin ? 'admin' : 'manager',
  });
  const [createBuilding, { isError: isErrorCreate }] = useCreateBuildingMutation();
  const [updateBuilding, { isError: isErrorUpdate }] = useUpdateBuildingMutation();
  const [deleteBuilding, { isError: isErrorDelete }] = useDeleteBuildingMutation();

  const organizationsQuery = {
    role: isAdmin ? 'admin' : isManager ? 'manager' : 'employer',
    page: 0,
    size: 300,
  };

  const { data: organizations } = useGetOrganizationsQuery(organizationsQuery);

  const [building, setBuilding] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    values.organization = { id: values.organization };
    createBuilding({ role: isAdmin ? 'admin' : isManager ? 'manager' : 'employer', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Здание "${response.name}" успешно добавлено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить здание. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setBuilding(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    values.organization = { id: values.organization };
    updateBuilding({
      role: isAdmin ? 'admin' : isManager ? 'manager' : 'employer',
      body: { id, ...values },
    })
      .unwrap()
      .then((response) => {
        setMessage(`Здание "${response.name}" успешно изменено.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить здание. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setBuilding(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить здание ${
        buildings.content.find((x) => x.id === obj.id).name
      }?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteBuilding({ role: isAdmin ? 'admin' : isManager ? 'manager' : 'employer', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Здание "${buildings.content.find((x) => x.id === response).name}" успешно удалено.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить здание. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Здания';
  }, []);

  return (
    <>
      <CreateModal
        organizations={organizations?.content ?? []}
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        building={building}
        organizations={organizations?.content ?? []}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        building={building}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-buildings my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Здания</h1>
            <hr />
          </div>

          <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
            Добавить здание
          </Button>

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <div className="buildings">
                {buildings?.content.map((obj) => (
                  <div
                    key={obj.id}
                    className="building-item box-shadow p-3 rounded-1 bg-body border border-light">
                    <div className="building-name">{obj.name}</div>
                    <div className="building-organization">{obj.organization.name}</div>
                    <div className="building-phone">{obj.phone}</div>
                    <div className="building-address">{obj.address}</div>

                    <div className="building-btns">
                      <Button
                        variant="outline-primary"
                        className="building-btn__edit"
                        onClick={() => modalShowEdit(obj)}>
                        <FaEdit /> Редактировать
                      </Button>

                      <Button
                        variant="outline-danger"
                        className="building-btn__delete"
                        onClick={() => modalShowDelete(obj)}>
                        <FaTrashAlt /> Удалить
                      </Button>
                    </div>
                  </div>
                ))}
              </div>

              <PaginationCustom totalPages={buildings.totalPages} page={page} setPage={setPage} />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Buildings;
