import React from 'react';
import { Link } from 'react-router-dom';

import Profile from '../../components/Layouts/Profile';

import { useGetUserGroupsQuery } from '../../services/profile';
import { ContentEmpty } from '../../components/ContentEmpty';

const ProfileGroups = () => {
  const { data: groups } = useGetUserGroupsQuery();

  return (
    <Profile>
      {groups?.length > 0 ? (
        <div className="groups">
          {groups?.map((x) => (
            <div className="group-item" key={x.id}>
              <div className="group-item__name">{x.name}</div>

              <Link to={`/programs/${x.program.id}`} className="group-item__program">
                {x.program.name}
              </Link>

              <div className="group-item__date">
                Обучения с {x.startDate.split('-').reverse().join('.')} до{' '}
                {x.endDate.split('-').reverse().join('.')}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ContentEmpty />
      )}
    </Profile>
  );
};

export default ProfileGroups;
