import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQueryWithAuth';

export const organizationsAPI = createApi({
  reducerPath: 'organizationsAPI',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['Organizations'],
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: ({ role, page = 0, size = 20, name = '' }) =>
        role === 'admin'
          ? `${role}/organizations/?page=${page}&size=${size}`
          : role === 'user'
          ? `${role}/organization-request/search${name ? `?name=${name}` : ''}`
          : `${role}/organization/`,
      providesTags: () => ['Organizations'],
    }),
    getRequests: builder.query({
      query: () => `manager/user/requests`,
      providesTags: () => ['Organizations'],
    }),
    updateRequestOrganization: builder.mutation({
      query: (body) => ({
        url: `manager/user/requests`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Organizations'],
    }),
    createOrganization: builder.mutation({
      query: ({ role, body }) => ({
        url: role === 'admin' ? `${role}/organizations/` : `${role}/organization/`,
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json;' },
      }),
      invalidatesTags: ['Organizations'],
    }),

    updateOrganization: builder.mutation({
      query: ({ role, body }) => ({
        url: role === 'admin' ? `${role}/organizations/${body.id}` : `${role}/organization`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => ['Organizations'],
    }),

    deleteOrganization: builder.mutation({
      query: ({ role, id }) => ({
        url: role === 'admin' ? `${role}/organizations/${id}` : `${role}/organization/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Organizations'],
    }),

    joinOrganization: builder.mutation({
      query: (id) => ({
        url: `user/organization-request/`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: () => ['Organizations'],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetRequestsQuery,
  useUpdateRequestOrganizationMutation,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useJoinOrganizationMutation,
} = organizationsAPI;
