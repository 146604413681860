import React from 'react';
import debounce from 'lodash.debounce';

import { useSearchParams } from 'react-router-dom';

import { Button, FloatingLabel, Form } from 'react-bootstrap';

import { FaEdit, FaShare, FaTrashAlt } from 'react-icons/fa';

import {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useJoinOrganizationMutation,
} from '../../services/organizations';

import CreateModal from '../../components/Modals/Organizations/Create';
import DeleteModal from '../../components/Modals/Organizations/Delete';
import EditModal from '../../components/Modals/Organizations/Edit';

import OrganizationItem from '../../components/Organizations';
import AlertMessage from '../../components/AlertMessage';
import PaginationCustom from '../../components/PaginationCustom';

import useAuth from '../../hooks/useAuth';

import './Organizations.scss';

const Organizations = () => {
  const { isAdmin, isManager, isUser } = useAuth();

  const [search, setSearch] = React.useState('');
  const [debouncedSearch, setDebouncedSearch] = React.useState('');

  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(
    searchParams.has('page') ? searchParams.get('page') - 1 : 0,
  );

  const organizationsQuery = {
    role: isAdmin ? 'admin' : isUser ? 'user' : 'manager',
    page: page,
    size: 18,
    name: debouncedSearch,
  };

  const { data: organizations, isLoading } = useGetOrganizationsQuery(organizationsQuery);
  const [createOrganization, { isError: isErrorCreate }] = useCreateOrganizationMutation();
  const [updateOrganization, { isError: isErrorUpdate }] = useUpdateOrganizationMutation();
  const [deleteOrganization, { isError: isErrorDelete }] = useDeleteOrganizationMutation();
  const [joinOrganization, { isError: isErrorJoin }] = useJoinOrganizationMutation();

  const [organization, setOrganization] = React.useState({});

  const [deleteMessage, setDeleteMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const updateSearchQuery = React.useMemo(
    () =>
      debounce((value) => {
        setDebouncedSearch(value);
      }, 500),
    [],
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    updateSearchQuery(value);
  };

  const clickJoinOrganization = (id) => {
    joinOrganization(id)
      .unwrap()
      .then(() => {
        setMessage(`Запрос в организацию успешно отправлен. Ожидайте подтверждения.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось отправить запрос.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });
  };

  const modalCloseCreate = () => setShowCreate(false);
  const modalShowCreate = () => setShowCreate(true);
  const submitCreate = (values) => {
    createOrganization({ role: isAdmin ? 'admin' : 'manager', body: values })
      .unwrap()
      .then((response) => {
        setMessage(`Организация "${response.name}" успешно добавлена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось добавить организацию. ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseCreate();
  };

  const modalCloseEdit = () => setShowEdit(false);
  const modalShowEdit = (obj) => {
    setOrganization(obj);
    setShowEdit(true);
  };
  const submitEdit = (id, values) => {
    updateOrganization({ role: isAdmin ? 'admin' : 'manager', body: { id, ...values } })
      .unwrap()
      .then((response) => {
        setMessage(`Организация "${response.name}" успешно изменена.`);
      })
      .catch((err) => {
        setMessage(
          `Не удалось обновить организацию.  ${
            err?.violations?.map((x) => x.message + '; ') ?? ''
          }`,
        );
      });

    modalCloseEdit();
  };

  const modalCloseDelete = () => setShowDelete(false);
  const modalShowDelete = (obj) => {
    setOrganization(obj);
    setDeleteMessage(
      `Вы уверены, что хотите удалить организацию` /*${
        organizations.content.find((x) => x.id === obj.id).name
      }*/ + `?`,
    );
    setShowDelete(true);
  };
  const submitDelete = (id) => {
    deleteOrganization({ role: isAdmin ? 'admin' : 'manager', id })
      .unwrap()
      .then((response) => {
        setMessage(
          `Организация "${
            organizations.content.find((x) => x.id === response).name
          }" успешно удалена.`,
        );
      })
      .catch((err) => {
        setMessage(
          `Не удалось удалить организацию.  ${err?.violations?.map((x) => x.message + '; ') ?? ''}`,
        );
      });

    modalCloseDelete();
  };

  React.useEffect(() => {
    document.title = 'ЦОПП - Организации';
  }, []);

  return (
    <>
      <CreateModal
        submitCreate={submitCreate}
        showCreate={showCreate}
        modalCloseCreate={modalCloseCreate}
      />

      <EditModal
        organization={organization}
        showEdit={showEdit}
        submitEdit={submitEdit}
        modalCloseEdit={modalCloseEdit}
      />

      <DeleteModal
        organization={organization}
        showDelete={showDelete}
        modalCloseDelete={modalCloseDelete}
        submitDelete={submitDelete}
        deleteMessage={deleteMessage}
      />

      <section className="section section-organizations my-5">
        <div className="container">
          <div className="section-title ">
            <h1>Организации</h1>
            <hr />
          </div>

          {isUser && (
            <FloatingLabel controlId="searchInput" label="Поиск..." className="mb-3">
              <Form.Control
                type="text"
                placeholder="Поиск..."
                value={search}
                onChange={handleInputChange}
              />
            </FloatingLabel>
          )}

          {((isManager && organizations?.content?.length === 0) || isAdmin) && (
            <Button variant="outline-primary" className="w-100 mb-3 py-2" onClick={modalShowCreate}>
              Добавить организацию
            </Button>
          )}

          {message && (
            <AlertMessage
              message={message}
              setMessage={setMessage}
              isError={isErrorCreate || isErrorUpdate || isErrorDelete || isErrorJoin}
            />
          )}

          {isLoading ? (
            <h1 className="text-center">Загрузка...</h1>
          ) : (
            <>
              <div className="organizations mb-3">
                {organizations?.content?.map((obj) => (
                  <div
                    className="organization-item box-shadow p-3 bg-body rounded-1 border border-light"
                    key={obj.id}>
                    <div className="organization-name">{obj.name}</div>

                    {(isAdmin || isManager) && (
                      <>
                        <div className="organization-phone">{obj.phone}</div>

                        <div className="organization-btns">
                          <Button variant="outline-primary" onClick={() => modalShowEdit(obj)}>
                            <FaEdit /> Редактировать
                          </Button>

                          <Button variant="outline-danger" onClick={() => modalShowDelete(obj)}>
                            <FaTrashAlt /> Удалить
                          </Button>
                        </div>
                      </>
                    )}

                    {isUser && (
                      <Button
                        variant="outline-primary"
                        onClick={() => clickJoinOrganization(obj?.id)}>
                        <FaShare className="me-1" /> Отправить запрос
                      </Button>
                    )}
                  </div>

                  /*<OrganizationItem
                  key={obj.id}
                  {...obj}
                  modalShowEdit={() => modalShowEdit(obj)}
                  modalShowDelete={() => modalShowDelete(obj)}
                />*/
                ))}
              </div>

              <PaginationCustom
                totalPages={organizations?.totalPages}
                page={page}
                setPage={setPage}
              />
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Organizations;
